import React from "react";
import moment from "moment";
import { numberToPhone,getConsentTypeLabel } from "../../utils";

const PatientDetailCell = (props) => {
  const { first_name, last_name, date_of_birth, phone, age, id, re_engaged,zip_code ,state, consent_type} = props.data;
  const queue = props.queue;
  return (
    <div>
      <div>
        <b>{`${first_name} ${last_name}`}</b>
      </div>
      <div>
        DOB: {moment(date_of_birth).format("MM/DD/YYYY")}, Age: {age}
      </div>
      <div style={{display:'flex'}}>
        {numberToPhone(phone)}
        {props.isCne && props.reengageIndicator && re_engaged && (
          <div
            style={{
              margin: "0 15px",
              border: "2px solid rgb(213, 161, 76)",
              padding: "1px 6px",
              borderRadius: 30,
              background: "rgb(228, 228, 228)",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Re-engage
          </div>
        )}
      </div>
      <div style={{ clear: 'both', textAlign: 'left', marginBottom: '3px' }} className="clearfix">
          <div style={{display:'flex', justifyContent:'space-between'}}>
              <div className="value-box" style={{ width: '100%' }}><span>ZIP Code:</span> {zip_code || 'N/A'}</div>
              <div className="value-box" style={{ width: '100%' }}><span>State:</span> {state || 'N/A'}</div>
          </div>
      </div>
      <div>
        CCM ID: {id}{" "}
        <span
          onClick={() => props.showNotes(id)}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            marginLeft: 10,
            color: "#337ab7",
          }}
        >
          {/* <i style={{color:'#337ab7',fontSize:24}} 
                className="fa fa-info-circle" title="Refresh" /> */}
          Notes
        </span>
      </div>
      {
        queue === 'search' && <div>
        Consent: {consent_type? getConsentTypeLabel(consent_type) : "-"}{" "}
        
      </div>
      }
    </div>
  );
};

export default PatientDetailCell;
