/**
 * Created by synerzip on 08/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Input,Modal,Button,FormGroup,InputGroup,FormControl} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var DateTimeField = require('react-bootstrap-datetimepicker-custom-icons');
var LinkedStateMixin = require('react-addons-linked-state-mixin');
import FormInput from '../../components/FormInput';
import config from '../../config';

class EditPatientInformationPopup extends React.Component {

    constructor(props){
        super(props);
        this.state={
            inputBirthDate:moment().format('MM-DD-YYYY'),
            okToText:props.patientInfo.ok_to_text != null ? props.patientInfo.ok_to_text : null,
            mobileNo:'',
            mobilePreferred:props.patientInfo.mobile_preferred
        };

        this.inputMobilePhn = '';
        this.inputEmailAdd = '';
        this.inputFirstName = '';
        this.inputHomePhn = '';
        this.inputLastName = '';
        this.inputMiddleName = '';
        this.inputBirthDate = '';
    }

    componentDidMount(){
        this.inputMobilePhn = (this.props.patientInfo.mobile)? this.props.patientInfo.mobile:'' ;
        this.inputEmailAdd = (this.props.patientInfo.email)? this.props.patientInfo.email:'' ;
        this.inputFirstName = (this.props.patientInfo.first_name)? this.props.patientInfo.first_name:'' ;
        this.inputHomePhn = (this.props.patientInfo.phone)? this.props.patientInfo.phone:'' ;
        this.inputLastName = (this.props.patientInfo.last_name)? this.props.patientInfo.last_name:'' ;
        this.inputMiddleName = (this.props.patientInfo.middle_name)? this.props.patientInfo.middle_name:'' ;

        this.setState({
            inputBirthDate:(this.props.patientInfo.date_of_birth) ? moment(this.props.patientInfo.date_of_birth).format('MM-DD-YYYY'):moment().format('MM-DD-YYYY')
        });

        if(this.inputHomePhn || this.inputMobilePhn){
            if(this.inputMobilePhn){
                this.setState({
                    requiredPhoneNum:true,
                    mobileNo:this.inputMobilePhn
                })
            }else{
                this.setState({
                    requiredPhoneNum:true,
                    mobileNo:''
                })
            }

        } else {
            this.setState({
                requiredPhoneNum:false
            })
        }

        const patientEmail = this.props.activeUser.email;
        const patientId = this.props.patientInfo.id;
        const ce_flag = this.props.activeUser.center && this.props.activeUser.center.ce_flag ?
            this.props.activeUser.center.ce_flag  : "false";
        const orgId = this.props.patientInfo.provider ? this.props.patientInfo.provider.organization_id : -1;
        const userObject = {
            identifier : patientId,
            email : patientEmail,
            custom : {
                "IsCandEUser": ce_flag+"",
                "OrganizationId": orgId+""
            }
        };

        // console.log(userObject);

    }

    showErrorMessage(title,message){
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    handleCancelOkAction(){
        this.props.onPopupHide();
        this.props.adminActions.resetUpdatedPatientDetailsStatus();
    }

    handleEditAction(e) {
        e.preventDefault();
        this.setState({
            enableEmail: true
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let result = this.validate();
        if(result.valid) {
        	const { address1, address2, city, state, zip_code } = this.props.patientInfo;
            var patientData = {
                date_of_birth:moment(this.state.inputBirthDate,'MM-DD-YYYY').format('YYYY-MM-DD'),
                email:this.inputEmailAdd,
                first_name:this.inputFirstName,
                last_name:this.inputLastName,
                middle_name:this.inputMiddleName,
	            address1,
	            address2,
	            city,
	            state,
	            zip_code,
                ok_to_text:this.state.okToText != null ? parseInt(this.state.okToText+'') : null,
                mobile_preferred: this.state.mobilePreferred === true ? 1 : 0
            };
            if(this.inputHomePhn !== '') patientData.phone = this.inputHomePhn;
            if(this.inputMobilePhn !== '') patientData.mobile = this.inputMobilePhn;
            this.props.adminActions.updatePatientDetails(this.props.selectedPatient.id,patientData)
            this.setState({
                showEnrollConfirmation:true,
                patientData:patientData,
                enableEmail:false
            });
            this.handleCancelOkAction();
        } else {
            this.showErrorMessage('Invalid Value',result.errorMessage);

            if(this.dateError) {
                this.setState({
                    dateError: this.dateError
                });
            }
        }
    }

    validate() {
        var valid = {valid: true};
        this.dateError = false;
        let references = {
            'inputFirstName': 'Please provide valid First name',
            'inputLastName': 'Please provide valid Last name',
            'inputMiddleName':'Please enter valid Middle name',
            'inputBirthDate':'Please enter valid birth date',
            'inputHomePhn':'Please enter valid home number',
            'inputMobilePhn':'Please enter valid mobile number',
            'inputEmailAdd': 'Please provide valid email'
        };

        let invalidEntry = false;
        invalidEntry = _.find(_.keys(references), ref => {
            if(this.refs[ref]) {
                if (this.refs[ref].validate()) {
                    this[ref] = this.refs[[ref]].getValue();
                } else {
                    return true;
                }

            }
        });

        invalidEntry = (this.checkVaildBirthDate(this.state.inputBirthDate)) ? (invalidEntry) ? invalidEntry:false : (invalidEntry) ? invalidEntry:'inputBirthDate';

        if( invalidEntry ) {
            valid = {
                valid: false,
                showError: true,
                errorMessage: (references[invalidEntry])?references[invalidEntry]:'Please select required checkbox'
            };
        }
        return valid;
    }

    checkVaildBirthDate(birthDateData){
        var selectedDateArr = birthDateData.split('-');
        var yr = parseInt(selectedDateArr[2]), mnth = parseInt(selectedDateArr[0] - 1), day = parseInt(selectedDateArr[1]);
        var birthDate = new Date(yr,mnth,day);
        var today = new Date();

        return (birthDate <= today && birthDateData !== 'Invalid date') ? true:false;
    }

    onMobilePreferredChange(e){
        this.setState({mobilePreferred:e.target.checked});
    }

    resetSubmittedStatus(){
        this.setState({

        });

        this.inputMobilePhn = '';
        this.inputEmailAdd = '';
        this.inputFirstName = '';
        this.inputHomePhn = '';
        this.inputLastName = '';
        this.inputMiddleName = '';
    }

    handleChangeDate(changedDate) {
        var selectedDateArr = changedDate.split('-');
        var yr = parseInt(selectedDateArr[2]), mnth = parseInt(selectedDateArr[0] - 1), day = parseInt(selectedDateArr[1]);
        var changedDt = moment.utc([yr,mnth,day]);
        this.setState({
            inputBirthDate:changedDt.format('MM-DD-YYYY')
        });

    }

    handlePhoneRequired(){
        this.inputHomePhn = this.refs['inputHomePhn'].getValue();
        this.inputMobilePhn = this.refs['inputMobilePhn'].getValue();
        if(this.inputHomePhn || this.inputMobilePhn){
            if(this.inputMobilePhn){
                this.setState({
                    requiredPhoneNum:true,
                    mobileNo:this.inputMobilePhn
                })
            }else{
                let okToText = this.state.okToText;
                if(this.state.okToText == 1){
                    okToText = null;
                }
                this.setState({
                    requiredPhoneNum:true,
                    okToText:okToText,
                    mobileNo:''
                })
            }
        } else {
            this.setState({
                requiredPhoneNum:false
            })
        }
    }

    onMobileNoChanged(e){
        this.setState({mobileNo:e.target.value});
    }

    disableEmail(){
        const isCne = this.props.activeUser && this.props.activeUser.center && this.props.activeUser.center.ce_flag;
        return !( isCne || this.props.adminUser )
    }

    onOkTextChanged(e){
        this.setState({okToText:e.target.value});
    }

    render() {
        var inputProps = {
            maxLength:'10',
            placeholder: 'MM/DD/YYYY'
        };

        const isCne = this.props.activeUser && this.props.activeUser.center && this.props.activeUser.center.ce_flag;
        const rpm_flag = this.props.activeUser && this.props.activeUser.center && this.props.activeUser.center.rpm_flag;
        const textClinical = this.props.patientInfo.provider.text_clinical;
        return (
            <Modal bsSize="large" show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>Edit Patient Details</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal edit-patient-information-form" style={{display:'inline-block'}}>
                        <div className="col-md-12">
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left">First Name:</label>
                                    <div className="col-md-9 remove-side-padding">
                                        <FormInput isRequired ref="inputFirstName" initialValue={this.inputFirstName} maxLength="150" readOnly={isCne && !this.props.adminUser}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left">Middle Name:</label>
                                    <div className="col-md-9 remove-side-padding">
                                        <FormInput ref="inputMiddleName" initialValue={this.inputMiddleName} maxLength="150" readOnly={isCne && !this.props.adminUser}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left">Last Name:</label>
                                    <div className="col-md-9 remove-side-padding">
                                        <FormInput isRequired ref="inputLastName" initialValue={this.inputLastName} maxLength="150" readOnly={isCne && !this.props.adminUser}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left">Date of Birth:</label>
                                    <div className={`col-md-9 remove-side-padding keep-icon ${isCne && !this.props.adminUser && 'disable-date-time-field'}`}>
                                        <DateTimeField
                                            dateTime={this.state.inputBirthDate}
                                            inputFormat='MM/DD/YYYY'
                                            format='MM-DD-YYYY'
                                            viewMode='years'
                                            onChange={this.handleChangeDate.bind(this)}
                                            inputProps={inputProps}
                                            iconStyle={{
                                                class: 'fa',
                                                time: 'fa-clock-o',
                                                calendar: 'fa-calendar',
                                                up: 'fa-chevron-up',
                                                down: 'fa-chevron-down',
                                                right: 'fa-chevron-right',
                                                left: 'fa-chevron-left',
                                            }}
                                            maxDate={moment()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left">Email Address:</label>
                                    <div className="col-md-9 remove-side-padding">
                                        <FormGroup>
                                          <InputGroup>
                                            <FormInput
                                                type="email"
                                                ref="inputEmailAdd"
                                                initialValue={this.inputEmailAdd}
                                                maxLength="150" />
                                          </InputGroup>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left"></label>
                                    <div className="col-md-9 remove-side-padding" style={{fontStyle:'italic'}}>
                                        Review whether home phone is a mobile device capable of receiving text messages.
                                        <br>If patient’s home phone is mobile device, populate mobile phone field.</br>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left">Home Phone:</label>
                                    <div className="col-md-9 remove-side-padding">
                                        <FormInput type="phone" isRequired={!this.state.requiredPhoneNum} ref="inputHomePhn" placeholder="(XXX) XXX-XXXX" handleBlurEvent={this.handlePhoneRequired.bind(this)} initialValue={this.inputHomePhn}  maxLength="25" readOnly={isCne && !this.props.adminUser}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left">Mobile Phone:</label>
                                    <div className="col-md-9 remove-side-padding">
                                        <FormInput type="phone" isRequired={!this.state.requiredPhoneNum}
                                                   ref="inputMobilePhn"
                                                   placeholder="(XXX) XXX-XXXX"
                                                   handleBlurEvent={this.handlePhoneRequired.bind(this)}
                                                   initialValue={this.inputMobilePhn}
                                                   maxLength="25"
                                                   readOnly={isCne && !this.props.adminUser}
                                                   onChange={this.onMobileNoChanged.bind(this)}
                                                    />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-11">
                                <div>
                                    <label className="col-md-3 control-label text-left"></label>
                                    <div className="col-md-9 remove-side-padding">
                                        <Input type="checkbox" className="control-label"
                                           label="Patient would prefer to be called at mobile phone"
                                           onChange={this.onMobilePreferredChange.bind(this)}
                                           style={{ display: 'inline-block',padding:0, marginTop:-15 }}
                                           checked={this.state.mobilePreferred} />
                                    </div>
                                </div>
                            </div>
                            {
                                !rpm_flag && (
                                    textClinical ? <div className="col-md-offset-1 col-md-11 edit-patient-info-container" style={{marginBottom:15}}>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label text-left">Ok to text:</label>
                                        <div className="col-md-9 remove-side-padding">
                                            <div>
                                                <input type="radio" name="okToText"
                                                       style={{float:'left',height:'15px !important'}}
                                                       value={1}
                                                       disabled={!this.state.mobileNo}
                                                       checked={this.state.okToText == 1}
                                                       onChange={this.onOkTextChanged.bind(this)}/>
                                                <div style={{float:'left',paddingTop:0, paddingLeft:15}}>
                                                    Patient consents to being contacted via text message at mobile phone.
                                                </div>
                                            </div>
                                            <div style={{clear:'both'}}>
                                                <input type="radio" name="okToText" style={{float:'left'}}
                                                       value={0}
                                                       checked={this.state.okToText == 0}
                                                       onChange={this.onOkTextChanged.bind(this)}/>
                                                <div style={{float:'left',paddingTop:18, paddingLeft:15}}>
                                                    Patient <span style={{fontWeight:'bold'}}>DOES NOT</span> consent to being contacted via text message.
                                                </div>
                                            </div>
                                            <div style={{clear:'both'}}>
                                                <input type="radio" name="okToText" style={{float:'left',height:'15px !important'}}
                                                       value={2}
                                                       checked={this.state.okToText == 2}
                                                       onChange={this.onOkTextChanged.bind(this)}/>
                                                <div style={{float:'left', paddingLeft:15}}>
                                                    No - Patient <span style={{fontWeight:'bold'}}>does not have access</span> to text message.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                :
                                <div className="col-md-offset-1 col-md-11 edit-patient-info-container" style={{marginBottom:15}}>
                                    <div className="form-group">
                                        <label className="col-md-3 control-label text-left">Ok to text:</label>
                                        <div className="col-md-9 remove-side-padding">
                                            <div style={{color:'red',padding:15}}>
                                            *Clinical Text disabled for Organization
                                            </div>
                                        </div>
                                    </div>
                                </div>            )
                            }


                            <hr/>
                            <div className="col-md-12" style={{textAlign:'right'}}>
                                <div className="">
                                    <Button bsStyle="default" onClick={this.handleCancelOkAction.bind(this)}>
                                        Cancel
                                    </Button>
                                    <Button bsStyle="success" style={{marginLeft:15}} onClick={this.handleSubmit.bind(this)}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        );

    }
}
reactMixin(EditPatientInformationPopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'activeUser': state.admin.activeUser,
    'selectedPatient':state.app.selectedPatient,
    'isPatientDetailsUpdated':state.app.isPatientDetailsUpdated,
    'adminUser': state.app.adminUser
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientInformationPopup);
