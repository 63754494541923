/**
 * Created by synerzip on 08/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input, Modal, Button, FormGroup, FormControl, Table, Row, Col, Grid} from 'react-bootstrap';
import { STATUSES } from '../../constants/other';
import _ from 'lodash';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import SpellCheckInput from '../../components/SpellCheckInput';

class CloseChartPopup extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            note: null,
            showNotes: false,
	        nextChart: '',
            selectedAssistanceTypes:{}
        }
    }

    componentWillMount() {
        if(!this.props.callLogSelections) {
            this.props.adminActions.getCallLogSelections();
        }
        if(!this.props.callAssistanceTypes){
            this.props.adminActions.getCallAssistanceTypes();
        }
    }

    showMessage(message, title = 'Invalid Value', status = 'error') {
        this.props.commonActions.showToast({
            status: status,
            title: title,
            message: message
        });
        const interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    updateNotes( e ) {
        this.setState({ note: e.target.value });
    }

    updateConfidentialNotes(e) {
        this.setState({ confidentialnote: e.target.value });
    }

    updateNextChart( e ) {
    	this.setState({ nextChart: e.target.value });
    }

    onAssistanceTypeSelected(assistanceType){
            const {selectedAssistanceTypes} = this.state;
            if( !selectedAssistanceTypes[assistanceType.id]){
                selectedAssistanceTypes[assistanceType.id] = true;
            }else{
                delete  selectedAssistanceTypes[assistanceType.id];
            }
            this.setState({
                selectedAssistanceTypes
            })
    }

    async onSubmit(e) {
        e.preventDefault();
        const {selectedAssistanceTypes,confidentialnote} = this.state;
        let log = this.state.log;
        let note = this.state.note ? this.state.note.trim() : '';
        if (!log) {
            this.showMessage('Please Select a Patient Contact Option Before Closing the Chart', 'Contact Log Missing');
            return;
        }else if (log !== '1' && log !== '2' && log !== '5' && !note ) {
            this.showMessage( 'Please provide a note before closing chart', 'Note Required' );
            return;
        }
        try{
            await this.props.adminActions.closeChart( this.props.selectedPatient.id, note, log, this.props.selectedQueue, this.props.activeUser.id,
                ( this.state.nextChart.trim() && this.state.nextChart ),_.keys(selectedAssistanceTypes),confidentialnote );
            this.setState({
                note:null
            });
            this.props.adminActions.clearWorkSummary();
            this.props.onPopupHide();
            if ( window[ 'closureTimer' ] ) {
                clearInterval( window[ 'closureTimer' ] );
            }
            if ( window[ 'pollingInterval' ] ) {
                clearInterval( window[ 'pollingInterval' ] );
            }
            if ( window[ 'secondaryPollingInterval' ] ) {
                clearInterval( window[ 'secondaryPollingInterval' ] )
            }
        }catch(error){
            this.showMessage('Unable to close chart', 'Error');
        }

    }


    onCancel() {
        this.setState({
            note:null
        });
        this.props.onPopupHide();
    }


    render() {
        const {selectedAssistanceTypes} = this.state;
        const mipsUrgentTodos  = _.filter(this.props.todoLists,(todo)=>{
           return todo.urgent && todo.urgent_todo_type_id === 23;
        });
        const urgentTodoList = _.map(mipsUrgentTodos,(mipsUrgentTodo)=>{
            return <div style={{marginTop:5,fontWeight:'normal',color:'rgb(120,120,120)'}}>
                {mipsUrgentTodo.description}
            </div>
        });
        const filteredAssociatedCharts = _.filter(this.props.associatedCharts,(chart)=>{
           return chart.status !== 4 && chart.status !== 3 && chart.status !== 106;
        });
        return (
            <Modal bsSize="large" show={this.props.show}>
                <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>{this.props.title}</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto',height:'auto'}}>
                        {
                            urgentTodoList.length > 0 &&
                            <div>
                                <div>
                                    <div style={{float:'left',fontWeight:'bold',marginBottom:10}}>
                                        <h4>MIPS Urgents to be completed</h4>
                                        {urgentTodoList}
                                    </div>
                                    <div style={{float:'right'}}>
                                        <Button bsStyle="primary" type="submit" style={{float: 'right'}}
                                        onClick={() => {this.props.onOptionSelect('todoList');
                                            this.onCancel();}}>
                                            To Do List
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        <FormGroup style={{clear:'both',paddingTop:5}}>
                            <h4>Patient Contact</h4>
                            <FormControl id="patientContactSelect" componentClass="select" valueLink={this.linkState('log')} defaultValue={-1}>
                                <option value={-1} disabled>Select Patient Contact</option>
                                {this.props.callLogSelections &&
                                _.map(this.props.callLogSelections, ( selection ) => {
                                    return (
                                        <option value={selection.id} key={selection.id} >{selection.code_description}</option>
                                    )
                                })

                                }
                            </FormControl>
                        </FormGroup>
                        { this.props.workSummary && this.props.workSummary.length ?
                            <div style={{paddingTop:2}}>
                                <h4 style={{ marginTop: '25px' }} id={"PatientCareCompleted"}>Patient Care Completed</h4>
                                <div style={{
                                    maxHeight: '250px',
                                    overflow: 'auto',
                                }}>
                                    <Table striped bordered id={"table_data"}>
                                        <thead>
                                        <tr>
                                            <th>Event Details</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.props.workSummary.map( ( workItem, index ) => {
                                            return (
                                                <tr id={'workitem_'+index} style={{ fontSize: '11px !important' }} key={`workitem_${index}`}>
                                                    <td>{ workItem.event_details }</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            : <h5>No work summary available.</h5>
                        }
                        <form onSubmit={this.onSubmit.bind(this)} style={{padding: '5px'}}>
                            { ( this.state.log && ( this.state.log === '4' || this.state.log === '6' || this.state.log === '8' || this.state.log === '9' || this.state.log === '10' || this.state.log === '11' ) ) &&
                            <div>
                                { ( this.state.log && ( this.state.log === '4' || this.state.log === '6') &&
                                <div style={{paddingTop:2}}>
                                    <h4 style={{ marginTop: '25px' }} id={"PatientAssisstentPerformed"}>Patient Assistance Performed</h4>
                                    <Grid style={{width:'100%'}}>
                                        <Row>
                                    {
                                        _.map(this.props.callAssistanceTypes,(assistanceType) => {
                                            return <Col xs={6} key={assistanceType.id}>
                                                <div style={{float:'left',marginLeft: 15,display:'flex',cursor:'pointer',
                                                    alignItems:'center',marginTop:10,marginBottom:10}}
                                                    onClick={this.onAssistanceTypeSelected.bind(this,assistanceType)}>
                                                    <input type="checkbox" style={{marginTop:0,height:'16px !important',cursor:'pointer'}}
                                                           checked={ selectedAssistanceTypes[assistanceType.id] ? true : false}/>
                                                    <div style={{float:'right',marginLeft:10}}>{assistanceType.name}</div>
                                                </div>
                                            </Col>
                                        })
                                    }
                                        </Row>
                                    </Grid>
                                </div>)
                                }
                                
                            </div>
                            }
                            {
                                this.state.log  && <div>
                            <h5 style={{ display: 'inline' }}>Notes</h5>
                            <span style={{
                                    float: 'right',
                                    color: 'red',
                                }}>
                                <strong>Notes are visible by patient. Please ensure proper spelling and grammar.</strong>
                            </span>
                            <div>
                                <textarea id="patientContactNote" onChange={ this.updateNotes.bind(this) }
                                            value={ this.state.note || '' }
                                            autoFocus="true"
                                            placeholder="Summarize conversation with patient here..."
                                            maxLength="4000"
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                padding: '5px',
                                                height: '175px',
                                                overflowY: 'auto',
                                                border: '1px solid #000',
                                                marginBottom: '1rem',
                                            }}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.note ? this.state.note.length : 0}/4000`}
                                    </div> 
                                </div>        
                             </div>
                            }

                            {
                                this.state.log  && this.props.loadNoteNeeded &&  <div>
                                <h5 style={{ display: 'inline' }}>Provider-Only Notes - Confidential (optional)</h5>
                                <span style={{
                                        float: 'right',
                                        color: 'red',
                                    }}>
                                    <strong></strong>
                                </span>
                                <div>
                                <textarea id="patientConfidentialNote" onChange={ this.updateConfidentialNotes.bind(this) }
                                            value={ this.state.confidentialnote || '' }
                                            placeholder="Optionally share notes that are visbile to the provider only"
                                            maxLength="4000"
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                padding: '5px',
                                                height: '175px',
                                                overflowY: 'auto',
                                                border: '1px solid #000',
                                                marginBottom: '1rem',
                                            }}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.confidentialnote ? this.state.confidentialnote.length : 0}/4000`}
                                    </div> 
                                </div>        
                             </div>
                            }    

                           
                            
	                        { !!this.props.associatedCharts.length &&
		                        <div style={{ marginBottom: '10px' }}>
			                        <h5 style={{ display: 'inline' }}>Associated Chart to Enter</h5>
			                        <select className="form-control" onChange={ this.updateNextChart.bind(this) } defaultValue={this.state.nextChart} >
				                        <option value="">None</option>
				                        { _.map( filteredAssociatedCharts, ( patient ) => {
				                            return <option key={patient.id} value={patient.id}>{patient.first_name} {patient.last_name} - {STATUSES[patient.status]}</option>
			                            })}
			                        </select>
		                        </div>
	                        }
                            <div style={{borderTop: '1px solid gray', height: 50, textAlign: 'center', paddingTop: 15}}>
                                <Button id="patientContactCancel" onClick={this.onCancel.bind(this)} style={{float: 'left'}}>
                                    Cancel
                                </Button>
                                <Button id="patientContactClose" bsStyle="danger" type="submit" style={{float: 'right'}}>
                                    Close
                                </Button>
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        );
    }
}
reactMixin(CloseChartPopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    selectedPatient: state.app.selectedPatient,
    selectedQueue:state.app.selectedQueue,
    callLogSelections:state.app.callLogSelections,
    callAssistanceTypes:state.app.callAssistanceTypes,
    activeUser: state.admin.activeUser,
    workSummary: state.admin.workSummary,
	associatedCharts: state.levelTwoDashboard.associatedCharts,
    todoLists: state.levelTwoDashboard.todoLists
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseChartPopup);


/*
<SpellCheckInput
    defaultValue={this.state.note}
    style={{ marginBottom: '1rem' }}
    onChange={(note) => { this.setState({ note }) }}
/>
*/