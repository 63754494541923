import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as adminActionCreator from './../actions/admin';
import * as commonActionCreator from './../actions/common';
import ContentLoader from "react-content-loader"
import { getTODOOpenMIPS, getCCMAssessmentsSummary, getOpenGoals, getCaseHistory } from "./../actions/admin"
import moment from 'moment'
import { isAdmin, isMA, numberToPhone } from '../utils'

const MyLoader = (props) => (
    <ContentLoader
        speed={5}
        width={400}
        height={150}
        viewBox="0 0 400 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="25" y="15" rx="5" ry="5" width="90%" height="10" />
        <rect x="25" y="45" rx="5" ry="5" width="90%" height="10" />
        <rect x="25" y="75" rx="5" ry="5" width="90%" height="10" />
        <rect x="25" y="105" rx="5" ry="5" width="90%" height="10" />
    </ContentLoader>
)

class ChartSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            copyLoading: false,
            mipsLoading: true,
            ccmAssessmentLoading: true,
            carePlanLoading: true,
            caseHistoryLoading: true,
            mipsUrgents: [],
            internalNotes: "",
            ccmAssessments: [],
            caseHistory: [],
            carePlans: []
        }
    }


    componentWillReceiveProps(nextProps) {

        if ((this.props.patient) &&
            (this.props.openChartSummary != nextProps.openChartSummary && nextProps.openChartSummary)) {
            this.setState({
                mipsLoading: true, ccmAssessmentLoading: true, carePlanLoading: true,
                caseHistoryLoading: true
            });
            getTODOOpenMIPS(nextProps.patient.id)
                .then((result) => {
                    this.setState({ mipsLoading: false, mipsUrgents: result })
                });

            getCCMAssessmentsSummary(nextProps.patient.id)
                .then((result) => {
                    this.setState({
                        ccmAssessmentLoading: false, ccmAssessments: _.filter(result, (assessment) => {
                            return assessment.latest_assessment_date != null
                        })
                    })
                });

            getOpenGoals(nextProps.patient.id)
                .then((result) => {
                    this.setState({ carePlanLoading: false, carePlans: result })
                });

            getCaseHistory(nextProps.patient.id)
                .then((result) => {
                    this.setState({ caseHistoryLoading: false, caseHistory: result })
                });



        }
    }

    copyValue(valueData, key) {
        this.setState({ copyLoading: key });
        const textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = valueData;
        document.documentElement.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.documentElement.removeChild(textArea);
        setTimeout(() => {
            this.setState({ copyLoading: false });
        }, 1000);

    }


    expandChartSummary() {
        this.props.adminActions.openChartSummary();
    }


    saveChanges(e) {
        this.setState({
            showChartSummary: false,
        })
    }

    hideSummary() {
        this.props.adminActions.closeChartSummary();
    }

    openChartTab(tabKey) {
        console.log(tabKey);
        this.props.adminActions.closeChartSummary();
        this.props.adminActions.openChartTab(tabKey);
    }

    openLink(e) {
        e.preventDefault();
        window.open(this.props.patient.provider.crs_link)
    }

    render() {
        const { openChartSummary } = this.props;
        const { patient,
            organizationNote,
            call_preference_description,
            engagementHistory,
            isRetSpec,
            show,
            userRole,
            generalNotes } = this.props;

            const isMAUser = isMA(userRole);    
        return (
            <div className={`chart-summary-container ${openChartSummary ? 'show-chart-summary' : ''}`}>
                <div className="chart-summary-header" style={{ display: openChartSummary ? 'block' : 'none' }}>
                    <div id="chart-summaryOpen" className="chart-summary-header-open">
                        <div style={{
                            display: 'flex', flex: 1,
                            justifyContent: 'center', alignItems: 'center'
                        }}>
                            <span>{patient.first_name + ' ' + patient.last_name}</span><span>-</span><span>Chart Summary</span>
                        </div>
                        <div style={{ position: 'absolute', right: 0, cursor: 'pointer' }}
                            onClick={this.hideSummary.bind(this)}>
                            <span><i className="fa fa-times fa-lg" aria-hidden="true"></i></span>
                        </div>
                    </div>

                </div>
                <div className="chart-summary-content" style={{ display: openChartSummary ? 'flex' : 'none', flexDirection: 'column' }}>
                    <div className="summary-box">
                        <div className='summary-title-box' style={{flex:0.75}}>
                            <div className='summary-title-item' onClick={this.copyValue.bind(this, patient.first_name, 'first_name')}>
                                {patient.first_name}
                                <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                <div
                                    style={{
                                        opacity: this.state.copyLoading == 'first_name' ? 0.9 : 0,
                                        position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        backgroundColor: '#3871A6', color: '#FFFFFF',
                                        transition: 'opacity 500ms ease'
                                    }}>Copied</div>

                            </div>
                            <div className='summary-title-item' onClick={this.copyValue.bind(this, patient.last_name, 'last_name')}>
                                {patient.last_name}
                                <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                <div
                                    style={{
                                        opacity: this.state.copyLoading == 'last_name' ? 0.9 : 0,
                                        position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        backgroundColor: '#3871A6', color: '#FFFFFF',
                                        transition: 'opacity 500ms ease'
                                    }}>Copied</div>
                            </div>
                            <div className='summary-title-item' onClick={this.copyValue.bind(this, patient.id, 'id')}>
                                {patient.id}
                                <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                <div
                                    style={{
                                        opacity: this.state.copyLoading == 'id' ? 0.9 : 0,
                                        position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        backgroundColor: '#3871A6', color: '#FFFFFF',
                                        transition: 'opacity 500ms ease'
                                    }}>Copied</div>
                            </div>
                            {
                                patient.email && <div className='summary-title-item' onClick={this.copyValue.bind(this, patient.email, 'email')}>
                                    {patient.email}
                                    <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                    <div
                                        style={{
                                            opacity: this.state.copyLoading == 'email' ? 0.9 : 0,
                                            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                            backgroundColor: '#3871A6', color: '#FFFFFF',
                                            transition: 'opacity 500ms ease'
                                        }}>Copied</div>
                                </div>
                            }

                            <div className='summary-title-item' onClick={this.copyValue.bind(this, moment(patient.date_of_birth).format('MM/DD/YYYY'), 'dob')}>
                                {moment(patient.date_of_birth).format('MM/DD/YYYY')}
                                <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                <div
                                    style={{
                                        opacity: this.state.copyLoading == 'dob' ? 0.9 : 0,
                                        position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        backgroundColor: '#3871A6', color: '#FFFFFF',
                                        transition: 'opacity 500ms ease'
                                    }}>Copied</div>
                            </div>
                            <div className='summary-item'>
                                <span style={{ fontWeight: 500 }}>POA:</span>
                                <span style={{ marginLeft: 10 }}>{patient.poa_name ? patient.poa_name : '-'}</span>
                            </div>
                            
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center',flex:0.25,overflow:'hidden'}}>
                            {
                                patient.icd10code_1 && <div className='summary-title-item' style={{flex:1,flexDirection: 'row', display: 'flex',overflow: 'hidden'}} onClick={this.copyValue.bind(this, patient.icd10code_1_desc ? patient.icd10code_1_desc : patient.icd10code_1, 'icd10code_1')}>
                                   <span style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',width: '95%'}}>{patient.icd10code_1_desc ? patient.icd10code_1_desc : patient.icd10code_1}</span> 
                                    <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                    <div
                                        style={{
                                            opacity: this.state.copyLoading == 'icd10code_1' ? 0.9 : 0,
                                            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                            backgroundColor: '#3871A6', color: '#FFFFFF',
                                            transition: 'opacity 500ms ease'
                                        }}>Copied</div>
                                </div>
                            }
                            {
                                patient.icd10code_2 &&  <div className='summary-title-item' style={{flex:1, flexDirection: 'row', display: 'flex',overflow: 'hidden'}} onClick={this.copyValue.bind(this, patient.icd10code_2_desc ? patient.icd10code_2_desc : patient.icd10code_2, 'icd10code_2')}>
                                    <span style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',width: '95%'}}>{patient.icd10code_2_desc ? patient.icd10code_2_desc : patient.icd10code_2}</span>
                                    <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                    <div
                                        style={{
                                            opacity: this.state.copyLoading == 'icd10code_2' ? 0.9 : 0,
                                            position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                            backgroundColor: '#3871A6', color: '#FFFFFF',
                                            transition: 'opacity 500ms ease'
                                        }}>Copied</div>
                                </div>
                            }


                        </div>
                    </div>
                    {
                        patient.apcm_enrollment === 'Candidate' && 
                        <div style={{padding:15, paddingTop:0, paddingBottom:0}}>
                                <label style={{color:'red'}}>APCM Program Candidate: Level {patient.apcm_eligible_level ? patient.apcm_eligible_level : 'Unkown'}</label>    
                        </div>
                    }
                   
                    <div style={{ display: 'flex', flex: 0.2 }}>
                        
                        <div className="summary-section">
                            <div>
                                <span className="summary-section-title">Provider Info</span>
                                <div>
                                    <div className='summary-section-content'>
                                        {
                                            patient.provider && patient.provider.crs_link &&
                                            <a style={{cursor:'pointer'}} onClick={this.openLink.bind(this)}>
                                                {patient.provider ? patient.provider.organization_secondary_name ?
                                                    patient.provider.organization_secondary_name :
                                                    patient.provider.organization : ""}
                                            </a>
                                        }

                                    </div>
                                    <div className='summary-section-content'>
                                        <span style={{ fontWeight: 600 }}>Provider:</span>
                                        <span> {patient.provider ? patient.provider.organization_secondary_name ?
                                                patient.provider.organization_secondary_name :
                                                patient.provider.organization : ""}
                                                {
                                                    patient.provider &&
                                                        patient.provider.location_secondary_name ?
                                                        `(${patient.provider.location_secondary_name})` : ""
                                                }
                                        </span>
                                    </div>
                                    <div className='summary-section-content'>
                                        <span style={{ fontWeight: 600 }}>Pronunciation:</span>
                                        <span> {patient.provider && patient.provider.pronunciation ? patient.provider.pronunciation : "-"}</span>
                                    </div>
                                    <div className='summary-section-content'>
                                        <span style={{ fontWeight: 600 }}>Provider Gender:</span>
                                        <span> {patient.provider && patient.provider.gender ? patient.provider.gender : "-"}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="summary-section">
                            <div style={{width:'100%'}}>
                                <span className="summary-section-title">Phone</span>
                                <div>
                                    <div className='summary-section-content'>
                                        <span style={{ fontWeight: 600 }}>Last called:</span>
                                        <span> {patient.last_call_ts
                                            ? moment.utc(patient.last_call_ts).local().format('h:mma MM/DD/YYYY')
                                            : 'N/A'
                                        }</span>
                                    </div>
                                    <div className='summary-section-content'>
                                        <span style={{ fontWeight: 600 }}>Timezone:</span>
                                        <span> {patient.provider && patient.provider.local_time_zone ? patient.provider.local_time_zone : '-'}</span>
                                    </div>
                                    <div className='summary-section-content'>
                                        <span style={{ fontWeight: 600 }}>Phone:
                                        </span> <div  className="summary-title-item" style={{ display:'inline-block',cursor: 'pointer', position: 'relative',padding:2 }} onClick={this.copyValue.bind(this, patient.phone, 'phone')}> <span> {numberToPhone(patient.phone)}{(patient.mobile_preferred === 0 && patient.phone) ? <span className="fa fa-asterisk" style={{ verticalAlign: 'super', fontSize: '5px', color: '#ff0000' }}></span> : ''}</span>
                                        <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                        <div
                                            style={{
                                                opacity: this.state.copyLoading == 'phone' ? 0.9 : 0,
                                                position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                backgroundColor: '#3871A6', color: '#FFFFFF',
                                                transition: 'opacity 500ms ease'
                                            }}>Copied</div>
                                        </div>
                                       
                                    </div>
                                    <div className='summary-section-content'>
                                        <span style={{ fontWeight: 600 }}>Mobile:
                                        </span> <div   className="summary-title-item" style={{ display:'inline-block',cursor: 'pointer', position: 'relative',padding:2 }} onClick={this.copyValue.bind(this, patient.phone, 'mobile')}>
                                        <span> {patient.mobile ? numberToPhone(patient.mobile) : "N/A"} {(patient.mobile_preferred === 1 && patient.mobile) ?
                                            <span className="fa fa-asterisk" style={{ verticalAlign: 'super', fontSize: '5px', color: '#ff0000' }}></span> : <span> </span>}</span>
                                        {
                                            patient.mobile && <i style={{ marginLeft: 10 }} className="fa fa-clipboard" aria-hidden="true"></i>
                                        }
                                        <div
                                            style={{
                                                opacity: this.state.copyLoading == 'mobile' ? 0.9 : 0,
                                                position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                backgroundColor: '#3871A6', color: '#FFFFFF',
                                                transition: 'opacity 500ms ease'
                                            }}>Copied</div>
                                            </div>
                                        
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left', padding:'5px 0 0' }} className="clearfix">
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <div className="value-box" style={{ width: '100%',padding:0 }}><span style={{ fontWeight: 'bold' }}>ZIP Code:</span> {patient.zip_code || 'N/A'}</div>
                                            <div className="value-box" style={{ width: '100%' ,padding:0}}><span style={{ fontWeight: 'bold' }}>State:</span> {patient.state || 'N/A'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="summary-section">
                            <div style={{ width: '100%' }}>
                                <span className="summary-section-title">Engagement History</span>
                                <div>
                                    {
                                        engagementHistory && engagementHistory.length > 0 ?
                                            _.map(engagementHistory, (engData, index) => {
                                                let label = '';
                                                let icon = '';
                                                let dateStr = '';
                                                if (engData.engagement_type == 1) {
                                                    label = 'No Answer';
                                                    icon = 'images/NoAnswerCall.png';
                                                    dateStr = moment(engData.engagement_ts).format('MM/DD/YYYY HH:mm')
                                                } else if (engData.engagement_type == 2) {
                                                    label = 'Outbound';
                                                    icon = 'images/outboundCall.png';
                                                    dateStr = moment(engData.engagement_ts).format('MM/DD/YYYY HH:mm')
                                                } else if (engData.engagement_type == 3) {
                                                    label = 'Inbound';
                                                    icon = 'images/InBoundCall.png';
                                                    dateStr = moment(engData.engagement_ts).format('MM/DD/YYYY HH:mm')
                                                }
                                                return <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                                    <div style={{ fontWeight: 600, width: 100 }}>{label}</div>
                                                    <div style={{
                                                        display: 'flex', justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <img src={icon} style={{ height: 18 }} />
                                                    </div>
                                                    <h6 style={{ textAlign: 'center', fontSize: 11 }}>{dateStr}</h6>
                                                </div>
                                            })
                                            :
                                            <div style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>No Data Available</div>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="summary-section" style={{ width: 422, maxWidth: 422 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto' }}>
                                <span className="summary-section-title">MIPS Urgents</span>
                                <div style={{ height: 1 }}>
                                    {this.state.mipsLoading ?
                                        <MyLoader />
                                        :
                                        <div style={{ width: '100%' }}>
                                            {
                                                (this.state.mipsUrgents && this.state.mipsUrgents.length > 0) ?
                                                    _.map(this.state.mipsUrgents, (data, index) => {
                                                        return <div key={data.id} style={{ padding: 4, marginTop: index != 0 ? 10 : 0, backgroundColor: '#dddddd' }}>
                                                            <div>
                                                                <span style={{ fontWeight: 600 }}>MIPS:</span>
                                                                <span style={{ paddingLeft: 10 }}>{data.description}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{ fontWeight: 600 }}>Due:</span>
                                                                <span style={{ paddingLeft: 10 }}>{moment(data.due_date).format('MM/DD/YYYY')}</span>
                                                            </div>
                                                        </div>
                                                    })
                                                    :
                                                    <div style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>No Data Available</div>
                                            }
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flex: 0.3 }}>
                        <div className="summary-section" style={{ flex: 1 }}>
                            <div>
                                <span className="summary-section-title">Org Notes</span>
                                <div className='summary-section-content' style={{ fontWeight: 600 }}>
                                    {""}
                                </div>
                                <div style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>
                                    {(!!organizationNote ? organizationNote : 'No organization notes available')}
                                </div>
                            </div>
                        </div>
                        <div className="summary-section" style={{ flex: 1 }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className="summary-section-title">Internal Notes</span>
                                    <i style={{ cursor: 'pointer' }} className="fa fa-external-link" aria-hidden="true" onClick={this.openChartTab.bind(this, 'generalNotes')}></i>
                                </div>
                                <div className='summary-section-content' style={{ fontWeight: 600 }}>
                                    {generalNotes && generalNotes.notes ? _.map(generalNotes.notes.split('\n'), (note) => {
                                        return <div key={note}>{_.map(note.split(")"), (noteData, index) => {
                                            return <span style={{ fontWeight: index == 0 ? 600 : 'normal' }}>
                                                {`${noteData}${index == 0 ? ')' : ''}`}
                                            </span>
                                        })}</div>
                                    })
                                        : <div style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>No Data Available</div>

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="summary-section" style={{ flex: 1 }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <span className="summary-section-title">CCM Assessments</span>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
                                    {this.state.ccmAssessmentLoading ?
                                        <MyLoader />
                                        :
                                        <div style={{ width: '100%', height: 1 }}>
                                            {
                                                this.state.ccmAssessments && this.state.ccmAssessments.length > 0
                                                    ? _.map(this.state.ccmAssessments, (assessment) => {
                                                        if (assessment.latest_assessment_date) {
                                                            return <div key={assessment.title} style={{ display: 'flex', paddingTop: 5, alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <div style={{ fontWeight: 600 }}>{assessment.title}</div>
                                                                <div>{moment(assessment.latest_assessment_date).format('MM/DD/YYYY')}</div>
                                                            </div>
                                                        }
                                                        return null;
                                                    })
                                                    :
                                                    <div style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>No Data Available</div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flex: 0.5 }}>
                        <div className="summary-section" style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className="summary-section-title">History - Previous 60 days</span>
                                    <i style={{ cursor: 'pointer' }} className="fa fa-external-link" aria-hidden="true" onClick={this.openChartTab.bind(this, 'caseHistory')}></i>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
                                    <div className='summary-section-content' style={{ height: 1 }}>
                                        <div style={{ paddingTop: 5 }}>
                                            {
                                                this.state.caseHistoryLoading ? <MyLoader /> :
                                                    this.state.caseHistory && this.state.caseHistory.length > 0
                                                        ? _.map(this.state.caseHistory, (history) => {
                                                            return <div className='summary-section-content' key={history.item_description}>
                                                                <div style={{ fontWeight: 600 }}>{moment(history.item_date).format('MM/DD/YYYY hh:MM a')}</div>
                                                                <div>{history.item_description}</div>
                                                            </div>
                                                        })
                                                        :
                                                        <div style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>No Data Available</div>

                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="summary-section" style={{ width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className="summary-section-title">Care Plans</span>
                                    <i style={{ cursor: 'pointer' }} className="fa fa-external-link" aria-hidden="true" onClick={this.openChartTab.bind(this, 'carePlanAndGoals')}></i>
                                </div>
                                {
                                    this.state.carePlanLoading ? <MyLoader /> :
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
                                            <div className='summary-section-content' style={{ height: 1 }}>
                                                {
                                                    this.state.carePlans && this.state.carePlans.length > 0 ?
                                                        _.map(this.state.carePlans, (plan) => {
                                                            return <div style={{ padding: 6,margin:6, backgroundColor:'#F1F1F1' }} key={plan.id}>
                                                                <div>
                                                                    <span style={{ fontWeight: 600 }}>Title:</span>
                                                                    <span style={{ paddingLeft: 15 }}>{plan.goal}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{ fontWeight: 600 }}>Frequency:</span>
                                                                    <span style={{ paddingLeft: 15 }}>Monthly</span>
                                                                    <span style={{ fontWeight: 600, paddingLeft: 15 }}>Due:</span>
                                                                    <span style={{ paddingLeft: 15 }}>{moment(plan.due_date).format('MM/DD/YYYY')}</span>
                                                                </div>
                                                            </div>
                                                        })
                                                        :
                                                        <div style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>No Data Available</div>
                                                }

                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    openChartSummary: state.admin.openChartSummary,
    userId: state.auth.id,
    'ccdaAvailable': state.levelTwoDashboard.ccdaAvailable,
    'medSummaryAvailable': state.levelTwoDashboard.medSummaryAvailable,
    'phsAvailable': state.levelTwoDashboard.phsAvailable,
    'confidentialNotesAvailable': state.levelTwoDashboard.confidentialNotesAvailable,
    'userId': state.auth.id,
    'userRole': state.auth.roles,
    'user': state.auth,
    'patientSSOToken': state.levelTwoDashboard.patientSSOToken,
    'app': state.app,
    'generalNotes': state.levelTwoDashboard.generalNotes,
    'patient': state.app.selectedPatient,
    'patientNotificationCount': state.notifications.patientNotificationCount,
    'form': state.form,
    'levelTwoDashboard': state.levelTwoDashboard,
    'activeUser': state.admin.activeUser,
    'patientSurveyQuestion': state.admin.patientSurveyQuestion,
    'patientSurveySubmitted': state.admin.patientSurveySubmitted,
    'chartClosureRequested': state.admin.chartClosureRequested,
    'selectedQueue': state.app.selectedQueue,
    'selectedPatient': state.app.selectedPatient,
    'organizationNote': state.levelTwoDashboard.organizationNote,
    'call_preference_description': state.levelTwoDashboard.call_preference_description,
    'engagementHistory': state.levelTwoDashboard.engagementHistory,
    'associatedCharts': state.levelTwoDashboard.associatedCharts,
    'configParams': state.admin.configParams,
    'optedOutReasons': state.levelTwoDashboard.optedOutReasons,
    'companies': state.admin.companies,
    'currentQueue': state.queue.currentQueue,
    'selectedPatientLastVisited': state.app.selectedPatientLastVisited
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartSummary);


