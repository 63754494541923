import React from 'react';
import _ from "lodash"

const ProgramDetailCell = ({data}) => {
        let apcmCandidate = false;
        const orgTypeIds = data.provider.org_type_ids;
        if(data.apcm_enrollment !== null){
                const apcmOrgType = _.find(orgTypeIds,(orgType) => orgType == 1);
                if(apcmOrgType){
                        apcmCandidate = true;
                }
        }
        let programType = data.program_type;
        if(programType === 'ccm'){
                programType = 'CCM';
        }else if(programType === 'pathways_stroke_care'){
                programType = 'Pathways Stroke Care';
        }else if(programType === 'apcm'){
                programType = 'APCM';
        }

        return <div>
                <div style={{fontWeight:600}}>
                        Active Program: {programType}
                </div>
                {
                        apcmCandidate && <div className="value-box" style={{ width: "100%", display:'flex', flex:1, flexDirection:'column'}}>
                                <div style={{display:'flex',justifyContent:'flex-start', padding:0}}>
                                        <span style={{fontWeight:400}}>APCM Enrollment:</span>
                                        <span style={{paddingLeft:15}}>{data.apcm_enrollment ? data.apcm_enrollment : 'Unknown'}</span>
                                </div>
                                <div style={{display:'flex',justifyContent:'flex-start', padding:0}}>
                                        <span style={{fontWeight:400}}>APCM Eligible Level:</span>
                                        <span style={{paddingLeft:15}}>{data.apcm_eligible_level ? data.apcm_eligible_level : '-'}</span>
                                </div>
                                <div style={{display:'flex',justifyContent:'flex-start', padding:0}}>
                                        <span style={{fontWeight:400}}>APCM Service Level:</span>
                                        <span style={{paddingLeft:15}}>{data.apcm_service_level ? data.apcm_service_level : '-'}</span>
                                </div>
                               
                        
                    </div> 

                }
                
               
        </div>
};

export default ProgramDetailCell;
