import React from 'react';
import moment from "moment";
import { Panel, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash'

const MyPatientStatusCell = ({ data: { urgent_flag,urgents_list, status_description, status, suspended,provider_transition,
    current_est_time,provider_transition_effective_date,reconsent,apcm_enrollment},urgentTypes }) => {
    let color = '#067809';
    if (status === 1) color = '#337ab7';
    if (urgent_flag === 1) color = '#f52b04';
    
    const transitionEffectiveDate = moment(provider_transition_effective_date);
    const currentESTTime = moment(current_est_time);
    const providerTransition = currentESTTime.isAfter(transitionEffectiveDate) && provider_transition;

    const urgentTypeKeyValue = {};
    _.each(urgentTypes,(urgentType) => {
        urgentTypeKeyValue[urgentType.id] = urgentType.name;
    });

    let showNoOfUrgent = 3;
    let apcmConsent = false;
    if(apcm_enrollment == 'Candidate'){
        apcmConsent = true;
        showNoOfUrgent--;
    }
    if(suspended){
        showNoOfUrgent--;
    }
    if(reconsent){
        showNoOfUrgent--;
    }
    
    if(providerTransition){
        showNoOfUrgent--;
    }

    urgents_list = urgents_list ? urgents_list : []
   
    const remainingUrgentLength = urgents_list ? urgents_list.length - showNoOfUrgent: 0;
    
    

   function popoverHoverFocus(remainingUrgentLength) {
        if(remainingUrgentLength <= 0){
            return <div/>;
        }
        const chips = [];
        for(let i=urgents_list.length - remainingUrgentLength; i<urgents_list.length; i++){
            chips.push(<div style={{padding:5}}>
                {urgentTypeKeyValue[urgents_list[i]+'']}
            </div> )
        }
        return (
            <Popover id="popover-trigger-hover-focus">
                {chips}
            </Popover>
        )
    }
    

    function getUrgentChips(){
        const chips = [];
        
        for(let i=0 ; i<showNoOfUrgent && i < urgents_list.length; i++){
            chips.push(<div key={i} className="new-patient-status" style={{border:'2px solid rgb(222,159,53)',padding:4,margin:2}}>
                {urgentTypeKeyValue[urgents_list[i]+'']}
            </div> )
        }
        
        return chips;
    }
    return (
        <div style={{ display: 'flex',flexDirection:'column',
            justifyContent:'center',alignItems:'center', color:'#000000' }}>
             <div style={{maxWidth:300, minWidth:150}}>
                    {
                        suspended && <div className="new-patient-status" style={{border:'2px solid rgb(184,62,39)',padding:4,margin:2}}>
                                    Suspended
                                </div>
                    }
                    {
                        apcmConsent && <div className="new-patient-status" style={{border:'2px solid rgb(184,62,39)',padding:4,margin:2}}>
                        APCM Consent
                    </div>
                    }
                    {
                        reconsent && <div className="new-patient-status" style={{border:'2px solid rgb(184,62,39)',padding:4,margin:2}}>
                                Re-consent
                            </div>
                    }
                    
                    {
                        providerTransition && <div className="new-patient-status" style={{border:'2px solid rgb(184,62,39)',padding:4,margin:2}}>
                            Provider Transition
                        </div>
                    }
                    {
                        getUrgentChips()
                    }

                    { remainingUrgentLength == 1 && 
                        <div className="new-patient-status" style={{border:'2px solid rgb(222,159,53)',padding:4,margin:2}}>
                              {urgentTypeKeyValue[urgents_list[urgents_list.length - remainingUrgentLength]+'']}
                        </div> 
                    } 
                   
                    { remainingUrgentLength > 1 && <OverlayTrigger trigger={['hover', 'focus']} placement='bottom' overlay={popoverHoverFocus(remainingUrgentLength-1)}>
                        <div className="new-patient-status" style={{border:'2px solid rgb(222,159,53)',padding:4,margin:2}}>
                        {urgentTypeKeyValue[urgents_list[urgents_list.length - remainingUrgentLength]+'']} <span style={{paddingLeft:5}}>(+{remainingUrgentLength-1})</span>
                        </div> 
                        </OverlayTrigger>
                    } 
                    
                </div>
        </div>
    );
};

export default MyPatientStatusCell;
