/**
 * Created by synerzip on 08/02/16.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import * as notificationsActionCreator from '../../actions/notifications';
import { connect } from 'react-redux';
import { Button, Nav, NavItem, NavDropdown, MenuItem, OverlayTrigger, Popover } from 'react-bootstrap';
import _ from 'lodash';
import TODOList from './TODOList';
import GAPSPlanList from './GAPSPlanList'
import CloseChartPopup from './CloseChartPopup';
import ReconsentRequiredModal from './ReconsentRequiredModal';
import APCMReconsentRequiredModal from './APCMReconsentRequiredModal'
import CaseHistoryView from './CaseHistoryView';
import CarePlanAndGoal from './CarePlanAndGoal';
import PatientEducation from "./PatientEducation";
import AssessmentView from './AssessmentView';
import MIPSAssessmentView from './MIPSAssessmentView';
import PathwayStrokeCareAssessmentView from './PathwayStrokeCareAssessmentView'
import GeneralNotes from './GeneralNotes';
import Labs from './Labs';
import WorkLoggedCell from './WorkLoggedCell';
import CCDA from './CCDA';
import PHS from './PHS';
import {
    PATIENT_STATUS_NEW,
    PATIENT_STATUS_UNVERIFIED,
    PATIENT_STATUS_AE_APPROVED,
    PATIENT_STATUS_OPTED_OUT,
    PATIENT_STATUS_RPM_UNVERIFIED,
    PATIENT_STATUS_SUPPORT
} from '../../constants/other';
import ChartspanWebComponent from './ChartspanWebComponent';
import InactivityCloseChartPopup from './InactivityCloseChartPopup';
import NotificationInboxItemDetail from '../notification/NotificationInboxItemDetail'
import ChartCaseMessagesList from '../caseMessages/ChartCaseMessagesList'
import { getConsentTypeLabel, isAdmin, isAutoDial, isMA, isTriage, numberToPhone } from '../../utils'
import { getValues } from 'redux-form';
import moment from 'moment';
import RequestRecordContainer from './RequestRecordContainer';
import MergePatientForm from './MergePatientForm';
import Utilities from './Utilities';
import NewRecordsDropdown from './NewRecordsDropdown';
import AssessmentsDropdown from './AssessmentsDropdown';
import OptOutModal from './OptOutModal';
import PatientSatisfactionModal from './PatientSatisfactionModal';
import ConfirmationModal from './ConfirmationModal';
import EmailConfirmationModal from './EmailConfirmationModal';
import ScheduleNextCallPopup from './ScheduleNextCallPopup'
import NotesModal from './NotesModal';
import PatientStatusUpdateModal from './PatientStatusUpdateModal';
import PageHeader from '../../components/PageHeader';
import NotificationOfChartClosureRequestModal from './NotificationOfChartClosureRequestModal';
import AssociatedChartsModal from './AssociatedChartsModal';
import config from "../../config";
import EditPatientInformationPopup from './EditPatientInformationPopup';

class LevelTwoDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.idleSecondsCounter = 0;
        this.idleSecondsInterval = null;
        this.idleTimeOut = 300;
        this.resetTimer = this.resetTimer.bind(this);
        this.checkInactivityTimeOut = this.checkInactivityTimeOut.bind(this);
        this.handleSelectSessionNotes = this.handleSelectSessionNotes.bind(this);
        this.closeChartTitle = 'Session Notes';
        this.state = {
            iframeURL: null,
            selectedOption: 'todoList',
            show: false,
            autoCloseChartShow: false,
            showEditPatientInfo: false,
            hasCheckedMedSummary: false,
            hasCheckedCCDA: false,
            showNotes: false,
            showMergePatient: false,
            showOptOut: false,
            showBillableLimit: false,
            showConfirmation: false,
            showScheduleNextCall: false,
            showEmailConfirmation: false,
            showSecondaryTimer: false,
            showNotificationOfChartClosureRequestModal: false,
            showPatientSatisfactionModal: false,
            showPatientStatusUpdate: false,
            satisfactionRating: null,
            satisfactionNotes: '',
            satisfactionDateDiff: null,
            showPatientServicesModal: false,
            notes: undefined,
            showAssociatedCharts: false,
            scheduleNextCallDate: null,
            scheduleNextCallSlot:null
        };
        this.handleScroll = _.debounce(this.handleScroll, 100);
    }

    closeChart() {
        this.closeChartTitle = 'Close Patient Chart';
        this.setState({ show: true });
        this.props.patient && this.props.adminActions.getWorkSummary(this.props.patient.id);
        this.showSecondaryTimer();
    }

    openReconsentModal() {
        this.setState({ showReconsentModal: true });
        this.showSecondaryTimer();
    }

    openAPCMReconsentModal() {
        this.setState({ showAPCMReconsentModal: true });
        this.showSecondaryTimer();
    }
    onReconsentAPCMPopupHide() {
        this.setState({ showAPCMReconsentModal: false });
        this.showSecondaryTimer();
    }

    onPopupHide() {
        this.setState({ show: false });
        this.showSecondaryTimer();
    }

    onReconsentPopupHide() {
        this.setState({ showReconsentModal: false });
        this.showSecondaryTimer();
    }

    onAutoCloseChartHide() {
        this.setState({ autoCloseChartShow: false })
        this.trackInactivity();
    }

    onAutoCloseChartShow() {
        this.setState({ autoCloseChartShow: true })
    }

    onOptionSelect(optionKey) {
        if (optionKey != 'newRecords') {
            var iframeLoading = false;
            if (optionKey == 'chartspan') {
                iframeLoading = true;
            }
            this.setState({ selectedOption: optionKey, iframeLoading: iframeLoading });
            this.props.adminActions.openChartTab('');
        }
        

    }

    componentWillMount() {
        const { companies, user, activeUser } = this.props;
        const isUserAdmin = isAdmin(user.roles)
        let ce_flag, isRetSpec;
        if (activeUser && activeUser.center && activeUser.center.company_id) {
            ce_flag = activeUser.center.ce_flag;
            isRetSpec = activeUser.center.support_flag;
        }

        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const isGAPSUser = activeUser && activeUser.center && activeUser.center.gaps_flag;

        if (this.props.activeUser && this.props.activeUser.center && (!isRPMUser && !isGAPSUser && !isRetSpec) ) {
            this.props.adminActions.getAssociatedCharts(this.props.patient.id);
        }
        this.props.adminActions.getPatientRecords(this.props.patient.id);
        this.props.adminActions.getGoalReviewFrequency();
        this.props.adminActions.getPatientCcmProiders(this.props.patient.id);
        if(!ce_flag && !isGAPSUser && !isRPMUser){
            this.props.adminActions.openChartSummary();
        }else{
            this.setState({showNotes:true});
        }

        //Fetch support reaons
        this.props.adminActions.getPatientServicesReasons();
        
    }

    componentDidMount() {
        const { companies, user, activeUser } = this.props;
        const isUserAdmin = isAdmin(user.roles)
        let ce_flag, isRetSpec;
        let company = null;
        if (activeUser && activeUser.center && activeUser.center.company_id) {
            company = _.result(_.find(companies, {'id':activeUser.center.company_id}), 'name');
            ce_flag = activeUser.center.ce_flag;
            isRetSpec = activeUser.center.support_flag;
        }
        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const isGAPS = activeUser && activeUser.center && activeUser.center.gaps_flag;
        this.trackInactivity();
        this.checkMedSummaryAvailability();
        this.checkCCDAAvailability();
        this.props.adminActions.getPatientDetail(this.props.patient.id);
        this.props.adminActions.fetchGeneralNotes(this.props.patient.id);
        if (isUserAdmin && !ce_flag && companies) {
            this.props.notificationsAction.getPatientNotificationsUnreadCount(this.props.patient.id);
            this.props.notificationsAction.getPatientNotificationMessage(this.props.patient.provider_id, this.props.patient.id);
        }

        this.props.adminActions.getOptOutReasons();
        if (this.props.patient.active_work_item_id) {
            this.props.adminActions.getPatientSurveyQuestion(this.props.patient.active_work_item_id);
            this.props.adminActions.getPatientSurveySubmission(this.props.patient.active_work_item_id);
        }
        if (this.props.patient.cs_userid) {
            this.props.adminActions.getLastPasswordReset(this.props.patient.id);
        }
        if (this.props.patient.provider.organization_id) {
            this.props.adminActions.getOrganizationNote(this.props.patient.provider.organization_id);
        }
        if(!isRPMUser && !isGAPS && !ce_flag){
            this.props.adminActions.getEngagementHistory(this.props.patient.id);     
        }
        if(ce_flag || (!isRPMUser && !isGAPS && !isRetSpec && !ce_flag)){
            this.props.adminActions.getLastVisitDate(this.props.patient.id);
        }
        if(isUserAdmin && !ce_flag && companies && this.props.patient.provider.integrated_notifications_enabled){
            this.props.notificationsAction.getPatientUnreadMessages(this.props.patient.provider.organization_id,
                this.props.patient.ehr_pid)
        }
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        this.removeInactivityTracking();
        this.props.adminActions.resetAssociatedCharts();
        this.props.notificationsAction.resetPatientCases();
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.patient.active_work_item_id !== this.props.patient.active_work_item_id) {
            this.props.adminActions.getPatientSurveyQuestion(nextProps.patient.active_work_item_id);
            this.props.adminActions.getPatientSurveySubmission(nextProps.patient.active_work_item_id);
        }
        if (nextProps.patient && nextProps.patient.provider && (nextProps.patient.provider.organization_id !== this.props.patient.provider.organization_id)) {
            
            this.props.adminActions.getOrganizationNote(nextProps.patient.provider.organization_id);
        }
        if (nextProps.chartClosureRequested && nextProps.chartClosureRequested.showNotificationOfChartClosureRequest === true && this.state.showNotificationOfChartClosureRequestModal === false) {
            this.setState({
                showConfirmReset: nextProps.app.showConfirmReset,
                formData: nextProps.form,
                lastPasswordReset: nextProps.levelTwoDashboard.lastPasswordReset || '',
                showNotificationOfChartClosureRequestModal: true,
            });
        } else {
            this.setState({
                showConfirmReset: nextProps.app.showConfirmReset,
                formData: nextProps.form,
                lastPasswordReset: nextProps.levelTwoDashboard.lastPasswordReset || '',
                showNotificationOfChartClosureRequestModal: false,
            });
        }
        if (nextProps.generalNotes !== this.props.generalNotes) {
            this.setState({ notes: nextProps.generalNotes.notes });
        }

        if(nextProps.chartTabTobeOpen && nextProps.chartTabTobeOpen !== this.props.chartTabTobeOpen){
            this.onOptionSelect(nextProps.chartTabTobeOpen)
        }

    }

    onScheduleDateSelected = (date) => {
        this.setState({ scheduleNextCallDate: date });
    }

    onScheduleSlotSelected = (slot) => {
        this.setState({ scheduleNextCallSlot: slot });
    }

    checkMedSummaryAvailability = () =>
        this.props.adminActions.getMedSummaryAvailability(this.props.selectedPatient.id)
            .then(() => {
                this.setState({ hasCheckedMedSummary: true });
            });

    checkCCDAAvailability = () =>
        this.props.adminActions.getCCDAAvailability(this.props.selectedPatient.id)
            .then(() => {
                this.setState({ hasCheckedCCDA: true });
            });

    handleScroll = () => {
        if (document.documentElement.scrollTop > 75) {
            document.getElementById('fixed-timer').style.top = '50px';
            document.getElementById('fixed-timer').style.zIndex = '10000';
        } else {
            document.getElementById('fixed-timer').style.top = '-20px';
            document.getElementById('fixed-timer').style.zIndex = '100';
        }
    }

    showSecondaryTimer = (isResetPassword = false) => {
        if (isResetPassword) {
            this.setState({ showSecondaryTimer: !this.state.showSecondaryTimer, showConfirmReset: !this.state.showConfirmReset }, () => {
                if (this.state.showSecondaryTimer) {
                    document.getElementById('fixed-timer').style.top = '50px';
                    document.getElementById('fixed-timer').style.zIndex = '10000';
                } else if (document.documentElement.scrollTop < 75) {
                    document.getElementById('fixed-timer').style.top = '-20px';
                    document.getElementById('fixed-timer').style.zIndex = '100';
                }
            });
        }

        this.setState({ showSecondaryTimer: !this.state.showSecondaryTimer }, () => {
            if (this.state.showSecondaryTimer) {
                document.getElementById('fixed-timer').style.top = '50px';
                document.getElementById('fixed-timer').style.zIndex = '10000';
            } else if (document.documentElement.scrollTop < 75) {
                document.getElementById('fixed-timer').style.top = '-20px';
                document.getElementById('fixed-timer').style.zIndex = '100';
            }
        });
    }

    handleVerify = (address1, address2, city, state, zip_code, callType, ok_to_text, mobileNo) => {
        const { activeUser,patient } = this.props;
        const {scheduleNextCallSlot} = this.state;
        const isRPM = activeUser && activeUser.center && activeUser.center.rpm_flag;
        this.props.adminActions.verifyPatient(this.props.patient, callType,isRPM)
            .then(() => {
                if (activeUser && activeUser.center && (activeUser.center.ce_flag || activeUser.center.rpm_flag)) {
                    this.props.adminActions.updateAddress(this.props.patient, address1, address2, city, state, zip_code, ok_to_text, mobileNo,()=>{
                        if(scheduleNextCallSlot){
                            this.props.adminActions.saveScheduleNextCall(patient.id,scheduleNextCallSlot.id);
                        }else{
                            this.props.adminActions.getPatientDetail(this.props.patient.id);
                        }

                        if(activeUser.center.rpm_flag){
                            this.props.adminActions.getRPMPerformance(this.props.userId);
                        }else if(activeUser.center.ce_flag){
                            this.props.adminActions.getCnePerformance(this.props.userId);
                        }

                    });
                }
                this.setState({ showConfirmation: false });
            });
    }

    handleEmailVerify = () => {
        this.props.adminActions.emailVerifyPatient(this.props.patient)
            .then(() => {
                const {activeUser} = this.props;
                this.props.adminActions.getPatientDetail(this.props.patient.id);
                if(activeUser.center.rpm_flag){
                    this.props.adminActions.getRPMPerformance(this.props.userId);
                }else if(activeUser.center.ce_flag){
                    this.props.adminActions.getCnePerformance(this.props.userId);
                }
                this.setState({ showEmailConfirmation: false });
            });
    }

    handleOptOut = (opt_out_id, optout_reason_id, notes) => {
        this.props.adminActions.optOutPatient(this.props.patient.id, opt_out_id, optout_reason_id, notes)
            .then(() => {
                const {activeUser} = this.props;
                this.props.adminActions.getPatientDetail(this.props.patient.id);
                if(activeUser.center.rpm_flag){
                    this.props.adminActions.getRPMPerformance(this.props.userId);
                }else if(activeUser.center.ce_flag){
                    this.props.adminActions.getCnePerformance(this.props.userId);
                }
                this.setState({ showOptOut: false });
            });
    }

    getTimeObject() {
        const millisecondsWorked = this.props.patient.work_millis_logged;
        const millisecondsRemaining = 1200000 - this.props.patient.work_millis_logged;
        let minutes = moment.duration(millisecondsRemaining).minutes();
        let seconds = moment.duration(millisecondsRemaining).seconds();
        let color = '#5cb85c';
        if (millisecondsWorked >= 900000 && millisecondsWorked < 1200000) {
            color = '#f0ad4e';
        } else if (millisecondsWorked >= 1200000) {
            color = '#d9534f';
        }

        let timeObject = {
            millisecondsWorked,
            minutes,
            seconds,
            color
        }

        return timeObject
    }

    trackInactivity() {
        var domNode = ReactDOM.findDOMNode(this.refs.levelTwoDashboard);
        window.addEventListener("mousemove", this.resetTimer, false);
        window.addEventListener("mousedown", this.resetTimer, false);
        window.addEventListener("keypress", this.resetTimer, false);
        window.addEventListener("DOMMouseScroll", this.resetTimer, false);
        window.addEventListener("mousewheel", this.resetTimer, false);
        window.addEventListener("touchmove", this.resetTimer, false);
        window.addEventListener("MSPointerMove", this.resetTimer, false);

        this.startTimer();
    }

    startTimer() {
        this.idleSecondsInterval = setInterval(this.checkInactivityTimeOut, 1000);
    }

    checkInactivityTimeOut(e) {
        this.idleSecondsCounter++;
        //console.log('The current timer count is >>> ',this.idleSecondsCounter);
        if (this.idleSecondsCounter >= this.idleTimeOut) {
            this.removeInactivityTracking();
            this.onAutoCloseChartShow();
        }
    }

    resetTimer(e) {
        clearInterval(this.idleSecondsInterval);
        this.idleSecondsInterval = null;
        this.idleSecondsCounter = 0;
        this.startTimer();
    }

    stopTimer() {
        clearInterval(this.idleSecondsInterval);
        this.idleSecondsInterval = null;
        this.idleSecondsCounter = 0;
    }

    copyNumber(number) {
        const textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = number;
        document.documentElement.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.documentElement.removeChild(textArea);
    }

    removeInactivityTracking() {
        var domNode = ReactDOM.findDOMNode(this.refs.levelTwoDashboard);
        window.removeEventListener("mousemove", this.resetTimer, false);
        window.removeEventListener("mousedown", this.resetTimer, false);
        window.removeEventListener("keypress", this.resetTimer, false);
        window.removeEventListener("DOMMouseScroll", this.resetTimer, false);
        window.removeEventListener("mousewheel", this.resetTimer, false);
        window.removeEventListener("touchmove", this.resetTimer, false);
        window.removeEventListener("MSPointerMove", this.resetTimer, false);

        this.stopTimer();
    }

    makeCall(id, phone) {
        this.props.appActions.sentMakeCallRequest({ id, phone });
    }

    enableMergePatient() {
        let email = this.props.patient.email;
        let emailDomain = email && email.substr(email.indexOf("@") + 1);
        return emailDomain == 'patient.chartspan.com' ? false : true
    }

    handleSelectSessionNotes() {
        this.onOptionSelect('generalNotes');
    }

    togglePatientSatisfaction() {
        this.showSecondaryTimer();
        this.setState({ showPatientSatisfactionModal: !this.state.showPatientSatisfactionModal });
    }

    updatePatientSatisfaction(field, e) {
        this.setState({ [field]: e.target.value });
    }

    submitPatientSatisfaction() {
        if (this.state.satisfactionRating) {
            const { patient, patientSurveyQuestion } = this.props;
            const { satisfactionRating, satisfactionNotes } = this.state;
            if (satisfactionRating === 'decline' && !satisfactionNotes.trim()) {
                this.showMessage('You must add notes if selecting decline', 'Failure');
                return;
            }
            const cleanedSatisfactionRating = satisfactionRating === 'decline' ? null : satisfactionRating;
            this.props.adminActions.postPatientSurvey(patient.active_work_item_id, cleanedSatisfactionRating, satisfactionNotes, patientSurveyQuestion['id'])
                .then((response) => {
                    if (response === true) {
                        this.togglePatientSatisfaction();
                        this.props.adminActions.getPatientSurveySubmission(patient.active_work_item_id);
                    }
                });
        } else {
            this.showMessage('Select a satisfaction level', 'Failure');
        }
    }

    handleConfirmChartClosureRequested = () => {
        this.props.adminActions.removeRequestForChartClosure();
    };

    handlePatientStatusChange(newStatus, reason, notes) {
        if (newStatus === 'Active') {
            this.props.adminActions.supportToActive(this.props.patient.id)
                .then((response) => {
                    if (response === true) {
                        this.setState({ showPatientStatusUpdate: false });
                    }
                })
        } else if (newStatus === 'Pending Unenroll') {
            if (reason === 6 && !notes.trim()) {
                this.showMessage('When selecting "other," notes must be provided', 'Reason missing');
                return;
            }
            if (reason !== 6) {
                notes = "";
            }

            this.props.adminActions.supportToPendingUnenroll(this.props.patient.id, notes, reason)
                .then((response) => {
                    if (response === true) {
                        this.setState({ showPatientStatusUpdate: false });
                    }
                })
        }
    }

    showMessage(message, title = 'Invalid Value', status = 'error') {
        this.props.commonActions.showToast({
            status: status,
            title: title,
            message: message
        });
        var interval = setInterval(() => {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    onEditPatientInfoShowHide() {
        this.showSecondaryTimer();
        this.setState({
            showEditPatientInfo: !this.state.showEditPatientInfo
        })
    }

    render() {
        const { hasCheckedMedSummary, hasCheckedCCDA } = this.state;
        const { patient, userRole, activeUser, ccdaAvailable, medSummaryAvailable, patientSurveySubmitted, patientSurveyQuestion, organizationNote, associatedCharts, currentQueue,
            call_preference_description,engagementHistory,selectedPatientLastVisited,patientServicesReasons } = this.props;
        const isCne = activeUser && activeUser.center && activeUser.center.ce_flag;
        const isRetSpec = activeUser && activeUser.center && activeUser.center.support_flag;
        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const isGAPSUser = activeUser && activeUser.center && activeUser.center.gaps_flag;
        const isMAUser = isMA(userRole);
        const isUserAdmin = isAdmin(userRole);
        const today = moment();
        let isButtonsAvailable = false;
        let loadNoteNeeded = false;

        let isSupportReasonAvailable = patient && patient.support_reason_id && 
            (((patient.from_support || patient.status === PATIENT_STATUS_SUPPORT)&& patient.program_type !== 'pathways_stroke_care') && 
            (isRetSpec || (!isRPMUser && !isGAPSUser && !isCne && !isRetSpec)));
        let supportReason = "";
        if(isSupportReasonAvailable && patientServicesReasons){
            //support_reason_id
            const reasonObject = _.find(patientServicesReasons,(reason) => reason.id == patient.support_reason_id);
            supportReason = reasonObject ? reasonObject.reason : "-";
        }
        const hidePatientSatisfaction =
            patient.status === PATIENT_STATUS_NEW ||
            isCne ||
            patient.show_nps === false ||
            patient.first_time_active_ts === null ||
            (patient.latest_survey_submission_ts !== null && today.diff(moment(this.props.patient.latest_survey_submission_ts), 'days') < 90) ||
            (patient.first_time_active_ts && today.diff(moment(this.props.patient.first_time_active_ts), 'days') < 30);
        const showVerifyButton =
            (patient.status === PATIENT_STATUS_NEW && !isCne) ||
            patient.status === PATIENT_STATUS_UNVERIFIED ||
            patient.status === PATIENT_STATUS_AE_APPROVED ||
            patient.status === PATIENT_STATUS_OPTED_OUT ||
            patient.status === PATIENT_STATUS_RPM_UNVERIFIED;
        const showOptOutButton =
            patient.status === PATIENT_STATUS_UNVERIFIED ||
            patient.status === PATIENT_STATUS_AE_APPROVED ||
            patient.status === PATIENT_STATUS_OPTED_OUT ||
            patient.status === PATIENT_STATUS_RPM_UNVERIFIED;
        const showEmailCneAddButton =
            (patient.status === PATIENT_STATUS_UNVERIFIED ||
                patient.status === PATIENT_STATUS_AE_APPROVED) &&
            isCne && isUserAdmin;
        const isRapidEval = patient && patient.provider && patient.provider.organization_rapid_eval;
        const messagesAvailable = patient && patient.provider && patient.provider.integrated_notifications_enabled;
        const isTextClinical = patient && patient.provider && patient.provider.text_clinical;
        const declineButtonText = isCne || isRPMUser ? 'Decline' : 'Opt Out';
        const confirmButtonText = isCne || isRPMUser ? 'Enroll' : 'Verify';
        const isSpecialty = patient && patient.provider && patient.provider.specialty_id !== 1;
        const isOrgFrozen = patient && patient.provider && patient.provider.organization_frozen;

        const disableButtonForFrozenOrg = isCne && isOrgFrozen;

        const ok_to_text = patient.ok_to_text;
        const ok_to_text_ts = patient.ok_to_text_ts;
        const sms_consent = patient.sms_consent;
        const sms_consent_ts = patient.sms_consent_ts;
        let okToTextValue = "";
        let dateString = "";
        let smsDateString = "";
        if (ok_to_text_ts) {
            dateString = moment.utc(ok_to_text_ts).local().format('MM/DD/YYYY');
        }
        if (sms_consent_ts) {
            smsDateString = moment.utc(sms_consent_ts).local().format('MM/DD/YYYY');
        }

        if (ok_to_text === null) {
            okToTextValue = "N/A";
        } else if (ok_to_text !== null && sms_consent === "duplicate_number") {
            okToTextValue = "Ineligible (Duplicate)";
        } else if (ok_to_text === 0) {
            okToTextValue = `No - ${dateString}`;
        }
        else if (ok_to_text === 1) {
            if(dateString){
                okToTextValue = `Yes - ${dateString}`;
            }else{
                okToTextValue = `Yes`;
            }
            
        }else if (ok_to_text === 1 && sms_consent.toLowerCase() === "false") {
            okToTextValue = `No - SMS decline - ${smsDateString}`;
        }

        if (showEmailCneAddButton || showVerifyButton
            || showOptOutButton || isRetSpec) {
            isButtonsAvailable = true;
        }

        let outboundNumber = config.CE_OUTBOUND_NUMBER;
        const location_outbound_number = patient && patient.provider && patient.provider.location_outbound_number;
        const organization_outbound_number = patient && patient.provider && patient.provider.organization_outbound_number;
        if (location_outbound_number) {
            outboundNumber = location_outbound_number;
        } else if (organization_outbound_number) {
            outboundNumber = organization_outbound_number;
        }

        let outboundClinicalNumber = config.CLINICAL_OUTBOUND_NUMBER;
        const location_outbound_clinical_number = patient && patient.provider && patient.provider.location_clinical_outbound_number;
        const organization_clinical_outbound_number = patient && patient.provider && patient.provider.organization_clinical_outbound_number;
        if (location_outbound_clinical_number) {
            outboundClinicalNumber = location_outbound_clinical_number;
        } else if (organization_clinical_outbound_number) {
            outboundClinicalNumber = organization_clinical_outbound_number;
        }

        let scheduleNextCall = '-';
        let scheduleNextLocalTimeCall = 'Schedule Next Call';
        let showScheduleNextCallInRed = false;
        const call_scheduled_slot_id = patient.call_scheduled_slot_id;
        if(call_scheduled_slot_id == 0){
            scheduleNextCall = 'Patient has no call preference'
        }else if (patient.call_scheduled_ts) {
            const scheduleTS = moment(patient.call_scheduled_ts);
            const currentESTTime = moment(patient.current_est_time);
            if(scheduleTS.isBefore(currentESTTime)
                && scheduleTS.isSame(currentESTTime,'year')
                && scheduleTS.isSame(currentESTTime,'month')){
                showScheduleNextCallInRed = true;
                scheduleNextCall = moment(patient.call_scheduled_ts).format('MM/DD/YYYY hh:mm a')
            }else if(!scheduleTS.isBefore(patient.current_est_time)) {
                scheduleNextCall = moment(patient.call_scheduled_ts).format('MM/DD/YYYY hh:mm a')
                scheduleNextLocalTimeCall = moment(patient.call_scheduled_ts_patient_tz).format('MM/DD/YYYY hh:mm a')
            }
        }

        if(!patient.schedulable){
            scheduleNextCall = 'Patient declines scheduling'
            scheduleNextLocalTimeCall = 'Patient declines scheduling'
        }
        const displayValue = isCne && scheduleNextCall == '-' ? 'none' : 'block';

        let programType = '-';
        if(isRPMUser){
            programType = 'Remote Monitoring'
        }else if(patient.program_type !== 'ccm'){
            programType = patient.program_type  == 'pathways_stroke_care' ? 'Pathways-Stroke' : '-';
        }else{
            let cptCode = '';
            if(patient.provider.location_billing_code){
                cptCode = patient.provider.location_billing_code;
            }else{
                cptCode = patient.provider.organization_billing_code
            }
            if(cptCode == "99490"){
                programType = 'Standard CCM'
            }else if(cptCode == "G0511"){
                programType = 'RHC/FQHC'
            }
        }
        let lastVisitedDate = '-';
        if(selectedPatientLastVisited && selectedPatientLastVisited.lastVisitDate){
            lastVisitedDate = moment(selectedPatientLastVisited.lastVisitDate).format('MM/DD/YYYY');
        }

        const latest_checkout_ts = patient.latest_checkout_ts;
        const latest_checkin_ts = patient.latest_checkin_ts;
        const inAutoDial = isAutoDial(latest_checkout_ts,latest_checkin_ts)

        const clinicalUser = !isCne && !isGAPSUser && !isRPMUser ;

        if(!isCne && !isGAPSUser && !isRPMUser && !isRetSpec && !isMAUser){
            loadNoteNeeded = true;
        }

        let apcmCandidate = false;
        if(patient.apcm_eligible_level !== null && patient.apcm_enrollment === 'Candidate'){
            apcmCandidate = true;
        }

        return (
            <div ref="levelTwoDashboard">
                <PageHeader style={{display:'flex', alignItems:'center'}}>
                    <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                        <div>
                        {isSpecialty ?
                            <span style={{ color: 'red' }}>{patient.provider.specialty_description} </span>
                            :
                            <span style={{paddingRight:5}}>Primary Care </span> }Chart
                        {
                            patient.reconsent && <span style={{ color: '#FF0000' ,paddingLeft:15,fontWeight:400}}>Patient Requires Re-Consent</span>
                        }
                        {
                                apcmCandidate && <span style={{ color: "red", marginLeft: '10px' }}> APCM Program Consent - Level {patient.apcm_eligible_level ? patient.apcm_eligible_level : '-'}</span>
                        }
                        {
                                isRPMUser && <span style={{ color: "red", marginLeft: '10px' }}> RPM Patient</span>
                        }
                        {
                        isRapidEval ?
                                <span style={{ color: "red", marginLeft: '10px' }}> RAPID EVAL PATIENT - RAPID EVAL PATIENT - RAPID EVAL PATIENT</span>
                                : ''}
                        {patient.from_support && patient.program_type !== 'pathways_stroke_care' ?
                            <span style={{ color: "red", marginLeft: '10px' }}> RECENTLY IN PATIENT SERVICES</span> :
                            !patient.from_support && patient.program_type == 'pathways_stroke_care' ?
                                <span style={{ color: "red", marginLeft: '10px' }}> Pathways - Stroke Care</span>
                                : patient.from_support && patient.program_type == 'pathways_stroke_care' ?
                                    <span style={{ color: "red", marginLeft: '10px' }}> Pathways - Stroke Care | RECENTLY IN PATIENT SERVICES</span>
                                    :
                                    ''}
                        {
                            !isGAPSUser && isCne && !isRPMUser && !isRetSpec && patient.re_engaged && <span
                            style={{
                            margin: "0 15px",
                            border: "2px solid rgb(213, 161, 76)",
                            padding: "6px",
                            borderRadius: 30,
                            background: "rgb(228, 228, 228)",
                            fontSize: 12,
                            fontWeight: 600,
                            }}
                        >
                            Re-engage
                        </span>
                        }   
                        </div>
                        <div>
                            {
                                patient.program_type === 'apcm' && <div style={{
                                    background:'#5cb85c', border:'1px solid #5cb85c', borderRadius:20, padding: '5px 12px',
                                    fontSize: 18,
                                    fontWeight: 500,
                                    color: '#FFFFFF'}}>
                                        APCM
                                </div>
                            }
                            {
                                patient.program_type === 'ccm' && <div style={{
                                    background:'#337ab7', border:'1px solid #337ab7', borderRadius:20, padding: '5px 12px',
                                    fontSize: 18,
                                    fontWeight: 500,
                                    color: '#FFFFFF'}}>
                                        CCM
                                </div>
                            }
                            
                        </div>
                    </div>
                             
                </PageHeader>
                <div
                    style={{
                        position: 'fixed',
                        top: '-20px',
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '70px',
                        width: '170px',
                        background: 'rgb(230,230,230)',
                        boxShadow: '-1px 1px 2px rgba(0,0,0,.3)',
                        borderBottomLeftRadius: '2px',
                        zIndex: 100,
                        transition: 'top 200ms',
                    }}
                    id="fixed-timer"
                >
                    <WorkLoggedCell data={patient} timerNeeded={true} />
                </div>

                <div className="disabledContainer">
                    <div>
                        <div style={{display:"flex"}} className="patient-summary-box">
                            <div style={{flex:0.25, borderRight:'1px solid #BDBDBD'}}>
                                <div className="patient-summary-item-box" style={{ width: '100%', borderRight:'none' }}>
                                    <div style={{ clear: 'both', textAlign: 'left' }} className="clearfix">
                                        <div className="value-box"
                                            style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Name:</span> {patient.first_name + ' ' + patient.last_name}
                                            {isRPMUser && <span style={{fontWeight:'bold'}}> (RPM Patient)</span>}
                                        </div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left' }} className="clearfix">
                                        <div className="value-box"
                                            style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Gender:</span> {patient.gender ? patient.gender : 'N/A'} </div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left', marginBottom: '3px' }} className="clearfix">
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>DOB:</span> {moment(patient.date_of_birth).format('MM/DD/YYYY') + ', Age:' + patient.age}</div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left', marginBottom: '3px' }} className="clearfix">
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Language:</span> {patient.preferred_language}</div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left', marginBottom: '3px' }} className="clearfix">
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>CCM ID:</span> {patient.id}</div>
                                    </div>
                                    {
                                        isCne &&
                                        <div style={{ clear: 'both', textAlign: 'left', marginBottom: '3px' }} className="clearfix">
                                            <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Outbound Number: </span>
                                                {numberToPhone(outboundNumber)}</div>
                                        </div>
                                    }

                                    {
                                        !isCne &&  !isRetSpec &&
                                        <div style={{ clear: 'both', textAlign: 'left', marginBottom: '3px' }} className="clearfix">
                                            <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Outbound Number: </span>
                                                {numberToPhone(outboundClinicalNumber)}</div>
                                        </div>
                                    }

                                    {!showOptOutButton &&
                                        <div style={{ clear: 'both', textAlign: 'left' }} className="clearfix">
                                            <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Enrolled: </span>{moment.utc(patient.billing_eligible_ts).local().format('MM/DD/YYYY')}</div>
                                        </div>
                                    }
                                    <div style={{ clear: 'both', textAlign: 'left' }} className="clearfix">
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Consent Type : </span>
                                        {patient.consent_type ? getConsentTypeLabel(patient.consent_type) : "-"}</div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left' }}>
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Email:</span> {patient.email}</div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left', marginBottom: '3px' }} className="clearfix">
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>ZIP Code:</span> {patient.zip_code || 'N/A'}</div>
                                            <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>State:</span> {patient.state || 'N/A'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{flex:0.25,  borderRight:'1px solid #BDBDBD'}}>
                                <div className="patient-summary-item-box" style={{ width: '100%', borderRight:'none' }}>
                                    {
                                        !isRPMUser && <div>
                                            <div style={{ clear: 'both', textAlign: 'left' ,display:displayValue}}>
                                                <div className="value-box"
                                                    style={{ width: '100%' }}>
                                                    <span style={{ fontWeight: 'bold',paddingRight:5 }}>
                                                        ChartSpan Appointment Time:
                                                    </span>
                                                    <span style={{color:showScheduleNextCallInRed && 'red'}}>
                                                        {`${showScheduleNextCallInRed ? '(Last Appt) ' : ''}${scheduleNextCall}`}
                                                    </span>
                                                </div>

                                            </div>
                                            <div style={{ clear: 'both', textAlign: 'left',display:displayValue }}>
                                                <div className="value-box" style={{ width: '100%' }}>
                                            <span style={{ fontWeight: 'bold' }}>
                                                Local Patient Appointment Time:</span>
                                                    {
                                                        !patient.schedulable ?
                                                            <span style={{paddingLeft:5}}>
                                                                {scheduleNextLocalTimeCall}
                                                            </span>
                                                            :
                                                            <Button bsStyle="link" onClick={() => this.setState({ showScheduleNextCall: true })} style={{ padding: 0, paddingLeft: 5, outline: 'none' }}>
                                                                {scheduleNextLocalTimeCall}
                                                            </Button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    }


                                    <div style={{ clear: 'both', textAlign: 'left' }}>
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Practice Time Zone:</span> {patient.provider && patient.provider.local_time_zone ? patient.provider.local_time_zone : '-'}</div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left' }}>
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Phone:</span> {(patient.phone) ?

                                            <div style={{ display: 'inline-block' }} onClick={this.copyNumber.bind(this, patient.phone)}>
                                                <OverlayTrigger trigger={['hover', 'focus']} placement='top'
                                                    overlay={<Popover id="popover-trigger-hover-focus">
                                                        <span>Copy</span>
                                                    </Popover>}>
                                                    <Button bsStyle="link" style={{ padding: 0, outline: 'none' }}>{numberToPhone(patient.phone)}</Button>
                                                </OverlayTrigger>
                                                {(patient.mobile_preferred === 0 && patient.phone) ? <span className="fa fa-asterisk" style={{ verticalAlign: 'super', fontSize: '5px', color: '#ff0000' }}></span> : ''}
                                                
                                            </div>

                                            : 'N/A'}

                                            {
                                                inAutoDial && <div style={{color:'#f52b04',display:'inline-block',marginLeft:10}}>
                                                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                <span style={{fontSize:12,paddingLeft:5}}>In Autodial Queue</span>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left' }}>
                                        <div className="value-box" style={{ width: '100%' }}>
                                            <span style={{ fontWeight: 'bold' }}>Last called: </span>
                                            {patient.last_call_ts
                                                ? moment.utc(patient.last_call_ts).local().format('h:mma MM/DD/YYYY')
                                                : 'N/A'
                                            }
                                        </div>
                                    </div>
                                    <div style={{ clear: 'both', textAlign: 'left' }}>
                                        <div className="value-box" style={{ width: '100%' }}>
                                            <span style={{ fontWeight: 'bold' }}>Mobile: </span>
                                            {(patient.mobile) ?
                                                <div style={{ display: 'inline-block' }} onClick={this.copyNumber.bind(this, patient.mobile)}>
                                                    <OverlayTrigger trigger={['hover', 'focus']} placement='top'
                                                        overlay={<Popover id="popover-trigger-hover-focus">
                                                            <span>Copy</span>
                                                        </Popover>}>
                                                        <Button bsStyle="link" style={{ padding: 0, outline: 'none' }}>
                                                            {numberToPhone(patient.mobile)}</Button>
                                                    </OverlayTrigger>
                                                    {(patient.mobile_preferred === 1 && patient.mobile) ?
                                                        <span className="fa fa-asterisk" style={{ verticalAlign: 'super', fontSize: '5px', color: '#ff0000' }}></span> : <span> </span>}
                                                </div> : 'N/A'}&nbsp;
                                            
                                </div>
                                    </div>
                                    {isTextClinical && !isRPMUser && !isCne && !isRetSpec &&
                                        <div style={{ clear: 'both', textAlign: 'left' }}>
                                            <div className="value-box" style={{ width: '100%' }}>
                                                <span style={{ fontWeight: 'bold' }}>Ok to Text: </span>
                                                {
                                                    okToTextValue !== 'N/A' ? okToTextValue
                                                        :
                                                        <button
                                                            type="button"
                                                            disabled={!isTextClinical}
                                                            className="btn btn-success"
                                                            style={{ padding: '2px 12px', marginLeft: 15 }}
                                                            onClick={this.onEditPatientInfoShowHide.bind(this)}
                                                        >
                                                            Text Consent
                                            </button>
                                                }

                                            </div>
                                        </div>
                                    }

                                    {(isCne || isRetSpec) &&
                                        <div style={{ clear: 'both', textAlign: 'left' }}>
                                            <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Insurance 1:</span> {patient.insurance1 || 'N/A'}
                                            </div>
                                        </div>
                                    }
                                    {(isCne || isRetSpec) &&
                                        <div style={{ clear: 'both', textAlign: 'left' }}>
                                            <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Insurance 2:</span> {patient.insurance2 || 'N/A'}</div>
                                        </div>
                                    }
                                    <div style={{ clear: 'both', textAlign: 'left' }}>
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Shared Chart:</span> {patient.profile_shared ? 'Yes' : 'No'}</div>
                                    </div>

                                    <div style={{ clear: 'both', textAlign: 'left' }}>
                                        <div className="value-box" style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>POA:</span> {patient.poa_name ? patient.poa_name : '-'}</div>
                                    </div>
                                    

                                    

                                    {(isCne || (!isRPMUser && !isGAPSUser && !isRetSpec && !isCne)) &&
                                        <div style={{ clear: 'both', textAlign: 'left' }}>
                                            <div className="value-box" style={{ width: '100%' }}>
                                                <span style={{ fontWeight: 'bold' }}>
                                                Last Visit Date:</span> {lastVisitedDate || '-'}
                                            </div>
                                        </div>
                                    }
                                    {/* <div className="value-box" style={{ textAlign: 'center', marginTop: '-2px', cursor: 'pointer' }}>
                                        <div className="btn-link" onClick={() => clinicalUser ? 
                                        this.props.adminActions.openChartSummary(): this.setState({showNotes:true})}>View Chart Summary</div>
                                    </div> */}
                                    {/* <div style={{ textAlign:"left",clear:'both', padding: isSupportReasonAvailable && 10, borderTop: isSupportReasonAvailable ? '1px solid #BDBDBD' : '' }}>
                                            <span style={{fontWeight:600}}>Support Reason:</span>
                                    </div> */}
                                </div>
                            </div>
                            <div style={{flex:0.5, display:"flex"}}>
                            <div className="patient-summary-item-box" style={{ width: '100%' }}>
                              <div className="value-box" style={{ width: '100%', textAlign: 'center' }}>
                                    {
                                        disableButtonForFrozenOrg ?
                                            <div style={{ color: '#d43f3a', borderRadius: 4,fontWeight: 'bold'  }}>
                                                <span>
                                                    {patient.provider ? patient.provider.organization : ""}
                                                    <span style={{ paddingLeft: 10 }}>
                                                        [Frozen]
                                                    </span>
                                                </span>
                                            </div>
                                            :
                                            <div style={{fontWeight: 'bold' }}>
                                                <div>
                                                    {patient.provider ? patient.provider.organization_secondary_name ?
                                                        patient.provider.organization_secondary_name :
                                                        patient.provider.organization : ""}
                                                </div>
                                                {
                                                    patient.provider &&
                                                    patient.provider.location_secondary_name ?
                                                        patient.provider.location_secondary_name : ""
                                                }
                                            </div>
                                    }

                                </div>
                                <br/>
                                <div className="value-box" style={{ width: '100%', textAlign: 'left' }}>
                                    <div><span style={{ fontWeight: 'bold' }}>Provider: </span><span style={{ textAlign: "left" }}>{patient.provider ? patient.provider.provider : ""}</span></div>
                                </div>
                                <div className="value-box" style={{ width: '100%', textAlign: 'left' }}>
                                    <div><span style={{ fontWeight: 'bold' }}>Pronunciation: </span><span style={{ textAlign: "left" }}>{patient.provider && patient.provider.pronunciation ? patient.provider.pronunciation : "-"}</span></div>
                                </div>
                                <div className="value-box" style={{ width: '100%', textAlign: 'left' }}>
                                    <div><span style={{ fontWeight: 'bold' }}>Provider Type: </span><span style={{ textAlign: "left" }}>{programType}</span></div>
                                </div>
                                <div className="value-box" style={{ width: '100%', textAlign: 'left' }}>
                                    <div><span style={{ fontWeight: 'bold' }}>Provider Gender: </span><span style={{ textAlign: "left" }}>{patient.provider && patient.provider.gender ? patient.provider.gender : "-"}</span></div>
                                </div>
                                
                                {/*
                        <div className="value-box" style={{width:'100%',textAlign:'center'}}>
                            <div>{patient.provider ? (patient.provider.address2) ? patient.provider.address1+' '+patient.provider.address2:patient.provider.address1: ""}</div>
                        </div>

                        <div className="value-box" style={{width:'100%',textAlign:'center'}}>
                            <div>{patient.provider ? patient.provider.city + "," + patient.provider.state + " " + patient.provider.zip_code: ""}</div>
                        </div>

                        */}
                               <br/>
                               <div className="value-box" style={{ width: '100%', textAlign: 'left' }}>
                                    <div><span style={{ fontWeight: 'bold' }}>Phone: </span><span style={{ textAlign: "left" }}>{patient.provider && patient.provider.phone ? numberToPhone(patient.provider.phone) : "-"}</span></div>
                                </div>
                                <div className="value-box" style={{ width: '100%', textAlign: 'left' }}>
                                    <div><span style={{ fontWeight: 'bold' }}>Fax: </span><span style={{ textAlign: "left" }}>{patient.provider && patient.provider.fax ? numberToPhone(patient.provider.fax) : "-"}</span></div>
                                </div>
                                <div className="value-box" style={{ width: '100%', textAlign: 'left' }}>
                                    <div><span style={{ fontWeight: 'bold' }}>After Hours: </span><span style={{ textAlign: "left" }}>{patient.provider && patient.provider.location_after_hours_phone ? numberToPhone(patient.provider.location_after_hours_phone) : "N/A"}</span></div>
                                </div>
                               
                            </div>
                            <div className="patient-summary-item-box" style={{ width: '100%',display: 'flex', alignItems: 'center',justifyContent:'center', flexDirection:'column', padding:0 }}>
                                <div className="value-box" style={{ width: "100%", display:'flex', flex:1, justifyContent:'center', alignItems:'center' }}>
                                    <WorkLoggedCell data={patient} timerNeeded={true} />
                                </div>
                                {
                                    patient.apcm_enrollment !== null && <div className="value-box" style={{ width: "100%", display:'flex', flex:1, borderTop:'1px solid #BDBDBD' , flexDirection:'column'}}>
                                    <div style={{display:'flex',justifyContent:'center', fontWeight:600}}>APCM Program</div>
                                    <div style={{display:'flex',justifyContent:'flex-start', padding:10, paddingBottom:0}}>
                                            <span style={{fontWeight:600}}>APCM Service Level:</span>
                                            <span style={{paddingLeft:15}}>{patient.apcm_service_level ? patient.apcm_service_level : '-'}</span>
                                    </div>
                                    <div style={{display:'flex',justifyContent:'flex-start', padding:10, paddingBottom:0}}>
                                            <span style={{fontWeight:400, fontStyle:'italic'}}>APCM Eligible Level:</span>
                                            <span style={{paddingLeft:15, fontStyle:'italic'}}>{patient.apcm_eligible_level ? patient.apcm_eligible_level : '-'}</span>
                                    </div>
                                    <div style={{display:'flex',justifyContent:'flex-start', padding:10, paddingBottom:0}}>
                                            <span style={{fontWeight:600}}>APCM Enrollment:</span>
                                            <span style={{paddingLeft:15}}>{patient.apcm_enrollment ? patient.apcm_enrollment : 'Unknown'}</span>
                                    </div>
                                </div> 
                                }
                                   
                            </div>
                            <div className="patient-summary-item-box" style={{ width: '100%',borderRight: 'none',  position: 'relative',justifyContent:'center',alignItems:'center' }}>
                                <div className="value-box" style={{
                                    width: "100%", margin: 0,
                                    position: 'absolute'
                                }}>
                                    <div style={{ lineHeight: 5 }}>
                                     {
                                        apcmCandidate && <Button id="patientCloseChart" onClick={this.openAPCMReconsentModal.bind(this)} className="btn-primary patient-close-chart-btn" bsStyle="danger">APCM Consent</Button>
                                     }   
                                     {patient.reconsent && <Button id="patientCloseChart" onClick={this.openReconsentModal.bind(this)} className="btn-primary patient-close-chart-btn" bsStyle="danger">Re-Consent Required</Button>}
                                        <Button id="patientCloseChart" onClick={this.closeChart.bind(this)} className="btn-primary patient-close-chart-btn">Close Patient Chart</Button>
                                        {hidePatientSatisfaction ? '' : <Button onClick={this.togglePatientSatisfaction.bind(this)} disabled={!!patientSurveySubmitted} className="btn-success patient-close-chart-btn">Patient Satisfaction</Button>}

                                        {(this.props.activeUser && this.props.activeUser.center && (!isRPMUser && !isGAPSUser && !isRetSpec) ) && Array.isArray(associatedCharts) && associatedCharts.length ? 
                                            <Button onClick={() => this.setState({ showAssociatedCharts: true })} className="btn-success patient-close-chart-btn" style={{background:"rgb(130,183,217)", border:'1px solid rgb(130,183,217)'}}>Associated Charts</Button> 
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div style={{display:"flex"}} className="patient-summary-box">
                            <div style={{flex:0.25, padding:0}} className="patient-summary-item-box">
                                <div style={{ padding:10,textAlign: 'center',clear:'both', padding: (isSupportReasonAvailable || isButtonsAvailable) && 10, 
                                    borderTop: (isSupportReasonAvailable || isButtonsAvailable) ? '1px solid #BDBDBD' : '' }}>
                                        {showVerifyButton &&
                                            <Button
                                                bsStyle={"success"}
                                                onClick={() => this.setState({ showConfirmation: true })}
                                                style={{ marginRight: '10px' }}
                                            >
                                                {confirmButtonText}
                                            </Button>
                                        }

                                        {showOptOutButton &&
                                            <Button
                                                bsStyle="danger"
                                                onClick={() => this.setState({ showOptOut: true })}
                                            >
                                                {declineButtonText}
                                            </Button>
                                        }

                                        {isRetSpec &&
                                            <Button
                                                bsStyle="primary"
                                                onClick={() => this.setState({ showPatientStatusUpdate: true }, this.props.adminActions.getUnenrollReasons)}
                                                style={{ display: 'block', margin: '10px auto' }}
                                            >
                                                Change Patient Status
                                    </Button>
                                        }
                                    </div>
                            </div>
                            <div style={{flex:0.25, padding:0}} className="patient-summary-item-box">
                               
                                     <div style={{ textAlign:"left", padding: (isButtonsAvailable || isSupportReasonAvailable) && 10, 
                                        borderTop: (isButtonsAvailable || isSupportReasonAvailable) ? '1px solid #BDBDBD' : '' }}>
                                       {
                                        isSupportReasonAvailable && <div><span style={{fontWeight:600}}>Support Reason:</span>
                                        <span style={{paddingLeft:15}}>{supportReason}</span>
                                        </div>
                                        
                                       } 
                                    </div>
                            </div>
                            <div style={{flex:0.5}}>
                             
                                <div style={{ textAlign:"left",padding: (isButtonsAvailable || isSupportReasonAvailable) && 10, borderTop: (isButtonsAvailable || isSupportReasonAvailable) ? '1px solid #BDBDBD' : '' }}>
                                {
                                        isSupportReasonAvailable && <div><span style={{fontWeight:600}}>Support Comment:</span>
                                            <span style={{paddingLeft:15}}>{patient.support_note ? patient.support_note : "-"}</span>
                                        </div>
                                       } 
                                </div>
                            </div>
                        </div>
                        <div className="patient-diagnosis-box" style={{display:'flex', justifyContent:'space-between'}}>
                            <div>
                                <div style={{ float: 'left' }}>
                                    <span style={{ paddingRight: 10, fontWeight: 'bold' }}>Diagnosis Codes</span>
                                </div>
                                <div style={{ float: 'left', paddingLeft: 10 }}>
                                    <div>{patient.icd10code_1} - {patient.icd10code_1_desc}</div>
                                    <div style={{ paddingTop: 0 }}>{patient.icd10code_2} - {patient.icd10code_2_desc}</div>
                                </div>
                            </div>
                            <a onClick={(e) => { 
                                e.preventDefault();
                                if(clinicalUser)
                                    this.props.adminActions.openChartSummary();
                                else this.setState({showNotes:true})
                            }}  style={{cursor:"pointer", padding:10}}>
                                View Chart Summary
                            </a> 
                        </div>
                    </div>

                    <div className="patient-top-bar" style={{display:'flex'}}>
                        {/* <div style={{width:'calc(100% - 155px)', overflow:'auto'}}> */}
                            <Nav style={{display:'flex',flex:1}} bsStyle="pills" activeKey={this.state.selectedOption} onSelect={this.onOptionSelect.bind(this)}
                                pullLeft>
                                <NavItem eventKey={'todoList'}>
                                    To Do List
                            </NavItem>
                                <NavItem eventKey={'generalNotes'}>
                                    Internal Notes
                            </NavItem>
                                {!isCne && !isRetSpec && !isRPMUser &&
                                    <NavItem eventKey={'chartspan'}>
                                        Patient Profile
                                </NavItem>
                                }
                                <NavItem eventKey={'caseHistory'}>
                                    History
                                </NavItem>
                                {!isGAPSUser && !isCne && !isRetSpec && !isRPMUser &&
                                    <NavDropdown title="Assessments" eventKey={'assessmentsOptions'} id="AssessmentDropdown">
                                        <MenuItem eventKey={"assessment"} id={"assessment"}>CCM Assessment</MenuItem>
                                        <MenuItem eventKey={"MIPSAssessment"} id={"MIPSAssessment"}>MIPS Assessment</MenuItem>
                                        {
                                            patient.program_type == 'pathways_stroke_care'
                                            && <MenuItem eventKey={"PathwayStrokeCare"}>Pathways - Stroke Care</MenuItem>
                                        }

                                    </NavDropdown>
                                }
                                {isGAPSUser &&
                                <NavItem eventKey={'gaps'}>
                                    GAPS
                                </NavItem>
                                }
                                {!isGAPSUser && !isCne && !isRetSpec && !isRPMUser &&
                                    <NavItem eventKey={'carePlanAndGoals'}>
                                        Care Goal
                                </NavItem>
                                }
                                {!isGAPSUser && !isCne && !isRetSpec && !isRPMUser &&
                                <NavItem eventKey={'patientEducation'}>
                                    Patient Education
                                </NavItem>
                                }


                                {!isGAPSUser && !isCne && !isRetSpec && !isRPMUser &&
                                    <NavItem eventKey={'labs'}>
                                        Labs
                                </NavItem>
                                }
                                {!isGAPSUser && !isCne && !isRetSpec && !isRPMUser &&
                                    <NavDropdown title="New Records" eventKey={'newRecords'} id="NewRecordsDropdown">
                                        <NewRecordsDropdown />
                                    </NavDropdown>
                                }
                                {!isGAPSUser && !isCne && !isRPMUser &&
                                    <NavItem eventKey={'ccda'} disabled={(hasCheckedMedSummary === false || hasCheckedCCDA === false) || (hasCheckedCCDA && hasCheckedMedSummary && !ccdaAvailable && !medSummaryAvailable)}>
                                        View CCDA
                                </NavItem>
                                }
                                {!isGAPSUser && !isCne && !isRPMUser &&
                                    <NavItem eventKey={'phs'}>
                                        View PHS
                                </NavItem>
                                }
                                {
                                    messagesAvailable && !isGAPSUser && isUserAdmin && !isCne && !isRPMUser ?
                                        <NavItem eventKey={'messagesDetail'} id="messagesPatientProfileTab">
                                            Cases
                                        {
                                                (this.props.patient && this.props.patient.unreadCases) ?
                                                    <span className="badge" style={{ fontSize:2,display: 'block', backgroundColor: '#FF0000', color: '#FFFFFF',
                                                    padding: 5,right: 4,top: 10, position:'absolute' }}>{" "}</span>
                                                    : ''
                                            }
                                        </NavItem>
                                        : ''
                                }
                                {
                                    !isGAPSUser && isUserAdmin && !isCne && !isRPMUser ?
                                        <NavItem eventKey={'notificationDetails'} id="notificationPatientProfileTab">
                                            Notification
                                        {
                                                (this.props.patientNotificationCount > 0) ?
                                                    <span className="badge" style={{ display: 'inline', backgroundColor: '#FF0000', color: '#FFFFFF' }}>{this.props.patientNotificationCount}</span>
                                                    : ''
                                            }
                                        </NavItem>
                                        : ''
                                }
                                {!isGAPSUser && !isCne && !isRetSpec && !isRPMUser &&
                                    <NavItem eventKey={'requestRecord'}>
                                        Request Record
                                </NavItem>
                                }
                                {
                                    <NavItem eventKey={'utilities'} id="patientUtility">
                                        Utilities
                                    </NavItem>
                                }

                            </Nav>
                        {/* </div> */}
                        
                    </div>
                </div>
                {
                    this.state.selectedOption == 'chartspan'
                        ?
                        <ChartspanWebComponent />
                        :
                        this.state.selectedOption == 'todoList'
                            ?
                            <TODOList showSecondaryTimer={this.showSecondaryTimer.bind(this)}
                                onOptionSelect={this.onOptionSelect.bind(this)} />
                            :
                            this.state.selectedOption == 'caseHistory'
                                ?
                                <CaseHistoryView />
                                :
                                this.state.selectedOption == 'assessment'
                                    ?
                                    <AssessmentView />
                                    :
                                    this.state.selectedOption == 'gaps'
                                    ?
                                        <GAPSPlanList showSecondaryTimer={this.showSecondaryTimer.bind(this)}
                                                  onOptionSelect={this.onOptionSelect.bind(this)} />
                                        :
                                    this.state.selectedOption == 'MIPSAssessment'
                                        ?
                                        <MIPSAssessmentView />
                                        :
                                        this.state.selectedOption == 'PathwayStrokeCare'
                                            ?
                                            <PathwayStrokeCareAssessmentView />
                                            :
                                            this.state.selectedOption == 'carePlanAndGoals'
                                                ?
                                                <CarePlanAndGoal showSecondaryTimer={this.showSecondaryTimer.bind(this)} />
                                                :
                                                this.state.selectedOption == 'patientEducation'
                                                    ?
                                                    <PatientEducation showSecondaryTimer={this.showSecondaryTimer.bind(this)} />
                                                    :
                                                this.state.selectedOption == 'generalNotes'
                                                    ?
                                                    <GeneralNotes />
                                                    :
                                                    this.state.selectedOption == 'labs'
                                                        ?
                                                        <Labs showSecondaryTimer={this.showSecondaryTimer.bind(this)} />
                                                        :
                                                        this.state.selectedOption == 'ccda'
                                                            ?
                                                            <CCDA />
                                                            :
                                                            this.state.selectedOption == 'phs'
                                                                ?
                                                                <PHS loadNoteNeeded={loadNoteNeeded}/>
                                                                :
                                                                this.state.selectedOption == 'notificationDetails'
                                                                    ?
                                                                    <NotificationInboxItemDetail unreadCount={this.props.patientNotificationCount} selectedPatient={patient} />
                                                                    :
                                                                    this.state.selectedOption == 'messagesDetail'
                                                                    ?
                                                                    <ChartCaseMessagesList unreadCount={this.props.patientNotificationCount} selectedPatient={patient} showSecondaryTimer={this.showSecondaryTimer.bind(this)}/>
                                                                    :
                                                                    this.state.selectedOption == 'requestRecord'
                                                                        ?
                                                                        <RequestRecordContainer />
                                                                        :
                                                                        this.state.selectedOption == 'utilities'
                                                                            ?
                                                                            <Utilities showConfirmReset={this.state.showConfirmReset} showSecondaryTimer={this.showSecondaryTimer.bind(this)} lastPasswordReset={this.state.lastPasswordReset} isCne={isCne} />
                                                                            :
                                                                            <div />
                }
                <ReconsentRequiredModal onPopupHide={this.onReconsentPopupHide.bind(this)} 
                    show={this.state.showReconsentModal} 
                    showSecondaryTimer={this.showSecondaryTimer.bind(this)} />
                <APCMReconsentRequiredModal onPopupHide={this.onReconsentAPCMPopupHide.bind(this)} 
                    show={this.state.showAPCMReconsentModal} 
                    showSecondaryTimer={this.showSecondaryTimer.bind(this)}/>    

                <CloseChartPopup onPopupHide={this.onPopupHide.bind(this)} 
                    show={this.state.show} title={this.closeChartTitle}
                    onOptionSelect={this.onOptionSelect.bind(this)}
                    showSecondaryTimer={this.showSecondaryTimer.bind(this)} 
                    loadNoteNeeded={loadNoteNeeded}/>
                <InactivityCloseChartPopup onPopupHide={this.onAutoCloseChartHide.bind(this)} show={this.state.autoCloseChartShow} />
                <OptOutModal
                    patient={patient}
                    currentQueue={currentQueue}
                    isRPM={isRPMUser}
                    show={this.state.showOptOut}
                    onHide={() => this.setState({ showOptOut: false })}
                    declineText={declineButtonText}
                    onConfirm={this.handleOptOut}
                    optedOutReasons={this.props.optedOutReasons}
                />
                <PatientSatisfactionModal
                    show={this.state.showPatientSatisfactionModal}
                    onHide={this.togglePatientSatisfaction.bind(this)}
                    onConfirm={this.submitPatientSatisfaction.bind(this)}
                    updateComponent={this.updatePatientSatisfaction.bind(this)}
                    question={patientSurveyQuestion}
                    rating={this.state.satisfactionRating}
                    notes={this.state.satisfactionNotes}
                />
                { isRetSpec &&
                    <PatientStatusUpdateModal
                        patient={patient}
                        show={this.state.showPatientStatusUpdate}
                        onHide={() => this.setState({ showPatientStatusUpdate: false })}
                        onConfirm={this.handlePatientStatusChange.bind(this)}
                    // confirmText={ confirmButtonText }
                    // onConfirm={this.handleVerify}
                    />
                }
                <ConfirmationModal
                    isCne={isCne}
                    isRPM={isRPMUser}
                    isClinical={ !isGAPSUser  && !isCne && !isRPMUser}
                    patient={patient}
                    show={this.state.showConfirmation}
                    onScheduleDateSelected={this.onScheduleDateSelected.bind(this)}
                    onScheduleSlotSelected={this.onScheduleSlotSelected.bind(this)}
                    scheduleNextCallSlot={this.state.scheduleNextCallSlot}
                    scheduleNextCallDate={this.state.scheduleNextCallDate}
                    onHide={() => this.setState({ showConfirmation: false })}
                    confirmText={confirmButtonText}
                    onConfirm={this.handleVerify}
                />
                <ScheduleNextCallPopup
                    show={this.state.showScheduleNextCall}
                    isCne={isCne}
                    isClinical={ !isGAPSUser  && !isCne && !isRPMUser}
                    onScheduleDateSelected={this.onScheduleDateSelected.bind(this)}
                    onScheduleSlotSelected={this.onScheduleSlotSelected.bind(this)}
                    scheduleNextCallSlot={this.state.scheduleNextCallSlot}
                    scheduleNextCallDate={this.state.scheduleNextCallDate}
                    onHide={() => this.setState({ showScheduleNextCall: false })} />
                <EmailConfirmationModal
                    show={this.state.showEmailConfirmation}
                    onHide={() => this.setState({ showEmailConfirmation: false })}
                    onConfirm={this.handleEmailVerify}
                />
                <NotesModal
                    patient={patient}
                    organizationNote={organizationNote}
                    call_preference_description={call_preference_description}
                    engagementHistory={engagementHistory}
                    isRetSpec={isRetSpec}
                    show={this.state.showNotes}
                    onHide={() => this.setState({ showNotes: false })}
                    notes={this.state.notes}

                />
                <NotificationOfChartClosureRequestModal
                    patient={patient}
                    show={this.state.showNotificationOfChartClosureRequestModal}
                    onConfirm={this.handleConfirmChartClosureRequested}
                />
                { (this.props.activeUser && this.props.activeUser.center && (!isRPMUser && !isGAPSUser && !isRetSpec) ) && !!this.props.associatedCharts.length &&
                    <AssociatedChartsModal
                        patients={this.props.associatedCharts}
                        show={this.state.showAssociatedCharts}
                        onHide={() => this.setState({ showAssociatedCharts: false })}
                    />
                }
                <EditPatientInformationPopup
                    onPopupHide={this.onEditPatientInfoShowHide.bind(this)}
                    show={this.state.showEditPatientInfo} patientInfo={patient} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    'ccdaAvailable': state.levelTwoDashboard.ccdaAvailable,
    'medSummaryAvailable': state.levelTwoDashboard.medSummaryAvailable,
    'phsAvailable': state.levelTwoDashboard.phsAvailable,
    'confidentialNotesAvailable': state.levelTwoDashboard.confidentialNotesAvailable,
    'userId': state.auth.id,
    'userRole': state.auth.roles,
    'user': state.auth,
    'patientSSOToken': state.levelTwoDashboard.patientSSOToken,
    'app': state.app,
    'generalNotes': state.levelTwoDashboard.generalNotes,
    'patient': state.app.selectedPatient,
    'patientNotificationCount': state.notifications.patientNotificationCount,
    'form': state.form,
    'levelTwoDashboard': state.levelTwoDashboard,
    'activeUser': state.admin.activeUser,
    'patientSurveyQuestion': state.admin.patientSurveyQuestion,
    'patientSurveySubmitted': state.admin.patientSurveySubmitted,
    'chartClosureRequested': state.admin.chartClosureRequested,
    'selectedQueue': state.app.selectedQueue,
    'selectedPatient': state.app.selectedPatient,
    'organizationNote': state.levelTwoDashboard.organizationNote,
    'call_preference_description':state.levelTwoDashboard.call_preference_description,
    'engagementHistory': state.levelTwoDashboard.engagementHistory,
    'associatedCharts': state.levelTwoDashboard.associatedCharts,
    'configParams': state.admin.configParams,
    'optedOutReasons': state.levelTwoDashboard.optedOutReasons,
    'companies': state.admin.companies,
    'currentQueue': state.queue.currentQueue,
    'selectedPatientLastVisited': state.app.selectedPatientLastVisited,
    'chartTabTobeOpen': state.levelTwoDashboard.chartTabTobeOpen,
    'patientServicesReasons': state.admin.patientServicesReasons,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions: bindActionCreators(appActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch),
    notificationsAction: bindActionCreators(notificationsActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LevelTwoDashboard);
