import React from "react";

const PatientNewRecordDataCell = (props) => {
  const { first_name, last_name, ce_flag, phone, age, id, re_engaged,zip_code ,call_count, call_attempts} = props.data;
  const queue = props.queue;
  return (
    <div>
      <div>
        <b>{`${first_name} ${last_name}`}</b>
      </div>
     
      <div>
        CCM ID: {id}{" "} 
      </div>
      <div>
        <b>Call Attempts:</b>  {!ce_flag ? (call_attempts ? call_attempts : " - " ):
                                                ( call_count ? call_count : " - ")} {" "} 
      </div>
    </div>
  );
};

export default PatientNewRecordDataCell;
