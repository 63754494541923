import React from 'react';
import { getConsentTypeLabel } from '../../utils';
const PatientWithStatusCell = ({ data: { first_name, last_name, status,id,consent_type,apcm_eligible_level,apcm_enrollment } }) =>
    <div>
        <div style={{fontWeight:600}}>{`${first_name} ${last_name}`}</div>
        <div>CCM ID: {id}{" "}</div>
        <div>Consent: {consent_type ? getConsentTypeLabel(consent_type) : "-"}</div>
        {
            apcm_enrollment === 'Candidate' && <div>APCM Eligible Level: {apcm_eligible_level ? apcm_eligible_level : "-"}</div>
        }
        
        <div style={{marginTop:10,width:50}}>
            {
                status == 1 && <div className="new-patient-status" style={{border:'2px solid rgb(109,173,130)'}}>
                                New
                            </div>
            }
            
        </div>
    </div>;

export default PatientWithStatusCell;
