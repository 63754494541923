/**
 * Created by synerzip on 08/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input, Modal, Button, FormGroup, FormControl, Table, Row, Col, ControlLabel} from 'react-bootstrap';
import { STATUSES } from '../../constants/other';
import _ from 'lodash';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import SpellCheckInput from '../../components/SpellCheckInput';

class APCMReconsentRequiredModal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            consentType: ''
        }
    }

    componentDidMount(){
        if(!this.props.apcmDeclineReasons){
            this.props.adminActions.getAPCMDeclinedReasons();
        }
    }

    onCancel() {
        this.props.onPopupHide();
    }

    reConsent() {
        const {selectedPatient,adminActions} = this.props;
        const {consentType} = this.state;

        adminActions.apcmTransitionApproved(selectedPatient.id,consentType);
        this.onCancel();
    }

    remainInCCM() {
        const {selectedPatient,adminActions} = this.props;
        adminActions.apcmTransitionDecline(selectedPatient.id, this.state.declineReason);
        this.onCancel();
    }


    render() {
        const {selectedPatient,apcmDeclineReasons} = this.props;
        return (
            <Modal bsSize="large" show={this.props.show}>
                <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>Program Transition Consent</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto',height:'auto'}}>
                        <div style={{padding:15}}>
                            <label style={{color:'red'}}>APCM Program: Level {selectedPatient.apcm_eligible_level}</label>
                            <label>
                                Patient is eligible to transition from CCM to APCM(Advance Primary Care Management) on behalf of {selectedPatient.provider ? selectedPatient.provider.provider : ""}.
                            </label>
                            <div style={{display:'flex',flex:1,margin:'15px 0',
                                    justifyContent:'space-evenly',
                                    alignItems:'center'}}>
                                <div style={{display:'flex',
                                flexDirection:'column',
                                justifyContent:'space-between',
                                padding:15,border:'1px solid rgb(232,232,232)',
                                borderRadius:4,
                                height: 300,
                                width: 250}}>
                                    <label>
                                        Patient chooses to transition to APCM. I have explained  the level-specific program components and copay.
                                    </label>
                                    <div>
                                        <ControlLabel>Consent Type</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            valueLink={this.linkState('consentType')}
                                            placeholder="Please select consent type"
                                            style={{marginBottom: '10px'}}
                                        >
                                            <option value='' selected disabled>Select an option</option>
                                            <option value={'INBOUND'}>Inbound</option>
                                            <option value={'OUTBOUND'}>Outbound</option>
                                            <option value={'SELF_ENROLLED'}>Self-Enrolled</option>
                                            
                                        </FormControl>
                                    </div>
                                    <Button onClick={this.reConsent.bind(this)} 
                                    disabled={!this.state.consentType}
                                    className="btn-primary" bsStyle="success">Transition to APCM</Button>
                                </div>
                                <div style={{display:'flex',
                                flexDirection:'column',
                                justifyContent:'space-between',
                                padding:15,border:'1px solid rgb(232,232,232)',
                                borderRadius:4,
                                height: 300,
                                width: 250}}>
                                    <label>
                                        Patient chooses to remain in the CCM  program.
                                    </label>
                                    <div>
                                        <br/>
                                        <br/>
                                        <ControlLabel>Decline Reason</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            valueLink={this.linkState('declineReason')}
                                            placeholder="Please select decline reason"
                                            style={{marginBottom: '10px'}}
                                        >
                                            <option value='' selected disabled>Select an option</option>
                                            {
                                                _.map(apcmDeclineReasons, (reason) => {
                                                   return <option value={reason.id} key={reason.id}>{reason.reason}</option>
                                                })
                                            }
                                            
                                        </FormControl>
                                    </div>
                                    <Button onClick={this.remainInCCM.bind(this)} className="btn-primary" bsStyle="danger">Remain in CCM</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{borderTop: '1px solid gray', height: 50, textAlign: 'center', paddingTop: 15}}>
                            <Button id="patientContactCancel" onClick={this.onCancel.bind(this)} style={{float: 'left'}}>
                                Cancel
                            </Button>
                        </div>
                    </div>


                </Modal.Body>

            </Modal>
        );
    }
}
reactMixin(APCMReconsentRequiredModal.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    selectedPatient: state.app.selectedPatient,
    selectedQueue:state.app.selectedQueue,
    callLogSelections:state.app.callLogSelections,
    callAssistanceTypes:state.app.callAssistanceTypes,
    activeUser: state.admin.activeUser,
    workSummary: state.admin.workSummary,
	associatedCharts: state.levelTwoDashboard.associatedCharts,
    todoLists: state.levelTwoDashboard.todoLists,
    apcmDeclineReasons: state.admin.apcmDeclineReasons
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(APCMReconsentRequiredModal);
