import {createConstants} from '../utils';

export default createConstants(
    'LOGIN_USER_REQUEST',
    'LOGIN_USER_FAILURE',
    'LOGIN_USER_SUCCESS',
    'LOGOUT_USER',

    //Common
    'SHOW_TOAST',
    'HIDE_TOAST',
    'REQUEST_SENT',
    'REQUEST_FAIL',

    //Common Level Constants
    'CONSENT_FORMS_RECEIVED',
    'MEDICAL_RECORD_RECEIVED',
    'PATIENT_DATA_RECEIVED',
    'UNASSIGNED_PATIENTS_RECEIVED',
    'MEDICAL_ASSISTANTS_RECEIVED',
    'MA_ASSIGNED_SUCCESSFULLY',
    'MA_DETAIL_RECEIVED',
    'INBOUND_CONSENT_FORM_RECEIVED',
    'CONSENT_DOC_RECEIVED',
    'INBOUND_MEDICAL_RECORD_RECEIVED',
    'INBOUND_MATCHED_MEDICAL_RECORD_RECEIVED',
    'MEDICAL_DOC_RECEIVED',
    'MATCH_APPROVAL_PATIENT_SELECTED',
    'CLEAR_MATCH_APPROVAL_PATIENT',
    'CLEAR_APPROVE_MEDICAL_FORM_DOC',
    'DELETE_INBOUND_RECORD_SUCCESS',

    //Level One Dashboard
    'LEVEL_TWO_DASHBOARD_DATA_RECEIVED',
    'PATIENT_SSO_TOKEN_RECEIVED',
    'PATIENT_DETAIL_RECEIVED',
    'PATIENT_DETAILS_UPDATED',
    'MAILING_ADDRESS_UPDATED',
    'RECEIVED_PATIENT_SURVEY_QUESTION',
    'RECEIVED_PATIENT_SURVEY_SUBMISSTION',
    'RECEIVED_ORGANIZATION_NOTE',
    'RECEIVED_PATIENT_SERVICES_REASONS',
    'RESET_PATIENT_UPDATE_STATUS',
    'TODO_ADDED_SUCCESSFULLY',
    'TODO_UPDATED_SUCCESSFULLY',
    'TODOS_MARK_COMPLETED',
    'TODO_MARK_UNCOMPLETED',
    'CASE_HISTORY_RECEIVED',
    'OPENED_PATIENT_ID',
    'ASSESSMENTS_RECEIVED',
    'ASSESSMENT_ADDED',
    'ASSESSMENT_DELETED',
    'ASSESSMENT_UPDATED',
    'NOTES_RECEIVED',
    'PATIENT_VERIFIED',
    'RESET_PASSWORD_COMPLETE',
    'PATIENT_SEARCH_RESULT_RECEIVED',
    'CLEAR_SEARCHED_PATIENT_LIST',
    'CONSENT_FORM_RECORD_SELECTED',
    'MEDICAL_RECORD_MATCHED_SUCCESSFULLY',
    'CARE_PLAN_AND_GOALS_RECEIVED',
    'GOAL_STEP_ADDED_SUCCESSFULLY',
    'GOAL_STEP_UPDATED_SUCCESSFULLY',
    'GOAL_STEP_DELETED_SUCCESSFULLY',
    'CHART_CLOSED_SUCCESSFULLY',
    'WORK_SUMMARY_RECEIVED',
    'CLEAR_WORK_SUMMARY',
    'SSO_TOKEN_RECEIVED',
    'UPDATE_PATIENT_SATISFACTION_PREFERENCES',
    'RECEIVED_ASSOCIATED_CHARTS',
    'NON_ACCOUNT_PATIENT_DETAIL_RECEIVED',
    'ACCOUNT_CREATED_SUCCESSFULLY',
    'GOAL_ADDED_SUCCESSFULLY',
    'GOAL_UPDATED_SUCCESSFULLY',
    'GOAL_DELETED_SUCCESSFULLY',
    'GOAL_COMPLETED_SUCCESSFULLY',
    'STEP_UPDATED_SUCCESSFULLY',
    'STEP_ADDED_SUCCESSFULLY',
    'CALL_LOG_SELECTIONS_RECEIVED',
    'LAB_UNITS_RECEIVED',
    'LABS_RECEIVED',
    'PATIENT_CCM_PROVIDERS_RECEIVED',
    'ASSESSMENT_QUESTIONS_RECEIVED',
    'ASSESSMENT_ANSWERS_RECEIVED',
    'UPDATE_ASSESSMENT_ANSWERS',
    'UPDATE_ASSESSMENT_UPDATES',
    'REQUEST_RECORD_INVALID',
    'REQUEST_RECORD_VALID',
    'SUBMIT_REQUEST_VALID',
    'RECEIVED_REQUEST_HISTORY',
    'SENT_RECORD_REQUESTS_SUCCESS',
    'MERGED_ACCOUNTS',
    'ACTIVE_USER_RECEIVED',
    'COMPANIES_RECEIVED',
    'RECEIVED_REPORTING_DATA',
    'REQUEST_COMPLETE',
    'CLEAR_MEDICAL_FORM_DOC',
    'SORT_REPORTS_ASCENDING',
    'SORT_REPORTS_DESCENDING',
    'RECEIVED_LAST_PASSWORD_RESET',
    'RECORDS_RECEIVED',
    'GET_RECORD_SUCCESS',
    'URGENT_TODO_TYPES_RECEIVED',
    'UTILIZATION_RECEIVED',
    'WHITEBOARD_EXISTS',
    'WHITEBOARD_CONTENT',
    'UPDATE_WHITEBOARD_CONTENT',
    'OPEN_WHITEBOARD',
    'CNE_PERFORMANCE_RECEIVED',
    'TODO_SOCIAL_DETERMINANTS_RECEIVED',
    'PATIENT_LAST_VISITED_DATE_RECEIVED',

    // Request Records
    'RECEIVED_PROVIDER_LIST',

    // Chart Opened By
    'CHART_OPEN_BY_REQUEST',
    'CHART_OPEN_BY_REQUEST_SUCCESS',
    'CHART_OPEN_BY_REQUEST_FAIL',

    // Initiate Unenroll Patient
    'INITIATE_UNENROLL_PATIENT_REQUEST',
    'INITIATE_UNENROLL_PATIENT_SUCCESS',
    'INITIATE_UNENROLL_PATIENT_FAIL',

    // Change Status to Support
    'SEND_TO_SUPPORT_SUCCESS',
    'SEND_TO_SUPPORT_FAILED',

    // Get Open Charts
    'GET_OPEN_CHARTS_REQUEST',
    'GET_OPEN_CHARTS_SUCCESS',
    'GET_OPEN_CHARTS_FAIL',

    // Close Chart by Admin
    'ADMIN_CLOSE_CHART_REQUEST',
    'ADMIN_CLOSE_CHART_SUCCESS',
    'ADMIN_CLOSE_CHART_FAIL',

    // CCDA Meta
    'GET_CCDA_AVAILABILITY_REQUEST',
    'GET_CCDA_AVAILABILITY_SUCCESS',
    'GET_CCDA_AVAILABILITY_FAIL',
    'GET_MED_SUMMARY_AVAILABILITY_SUCCESS',
    'GET_MED_SUMMARY_AVAILABILITY_FAILURE',

    // CCDA PDF
    'GET_CCDA_PDF_REQUEST',
    'GET_CCDA_PDF_SUCCESS',
    'GET_CCDA_PDF_FAIL',
    'GET_MED_SUMMARY_PDF_REQUEST',
    'GET_MED_SUMMARY_PDF_SUCCESS',
    'GET_MED_SUMMARY_PDF_FAILURE',

    // PHS Meta
    'GET_PHS_AVAILABILITY_REQUEST',
    'GET_PHS_AVAILABILITY_SUCCESS',
    'GET_PHS_AVAILABILITY_FAIL',

    // PHS PDF
    'GET_PHS_PDF_REQUEST',
    'GET_PHS_PDF_SUCCESS',
    'GET_PHS_PDF_FAIL',

    // Confidential Notes Meta
    'GET_CONFIDENTIAL_NOTES_AVAILABILITY_REQUEST',
    'GET_CONFIDENTIAL_NOTES_AVAILABILITY_SUCCESS',
    'GET_CONFIDENTIAL_NOTES_AVAILABILITY_FAIL',

    // Confidential Notes PDF
    'GET_CONFIDENTIAL_NOTES_PDF_REQUEST',
    'GET_CONFIDENTIAL_NOTES_PDF_SUCCESS',
    'GET_CONFIDENTIAL_NOTES_PDF_FAIL',

    // Get notifications unread count
    'CCM_NOTIFICATION_UNREAD_REQUEST_SENT',
    'CCM_NOTIFICATION_UNREAD_REQUEST_SUCCESS',
    'CCM_NOTIFICATION_UNREAD_REQUEST_FAIL',

    //CCM Notification threads
    'CCM_NOTIFICATION_THREADS_REQUEST_SENT',
    'CCM_NOTIFICATION_THREADS_REQUEST_SUCCESS',
    'CCM_NOTIFICATION_THREADS_REQUEST_FAIL',

    //CCM Notification get patient details
    'CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT',
    'CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SUCCESS',
    'CCM_NOTIFICATION_PATIENT_DATA_REQUEST_FAIL',

    //CCM Notification get patient unread count
    'CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SENT',
    'CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SUCCESS',
    'CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_FAIL',

    //CCM Notification get patient messages
    'CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SENT',
    'CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SUCCESS',
    'CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_FAIL',
    'RESET_CCM_NOTIFICATION_THREAD_MESSAGE',

    //CCM Notification mark patient messages
    'CCM_READ_NOTIFICATION_THREAD_REQUEST_SENT',
    'CCM_READ_NOTIFICATION_THREAD_REQUEST_SUCCESS',
    'CCM_READ_NOTIFICATION_THREAD_REQUEST_FAIL',

    //CCM Notification mark patient messages
    'CCM_NOTIFICATION_REPLY_REQUEST_SENT',
    'CCM_NOTIFICATION_REPLY_REQUEST_SUCCESS',
    'CCM_NOTIFICATION_REPLY_REQUEST_FAIL',

    //Make a call
    'MAKE_CALL_SENT',
    'MAKE_CALL_SUCCESS',
    'MAKE_CALL_FAIL',

    'FETCH_QUEUE_REQUEST',
    'FETCH_QUEUE_SUCCESS',
    'FETCH_QUEUE_FAILURE',
    'FETCH_LOCATIONS_SUCCESS',
    'FETCH_ORGS_SUCCESS',
    'FETCH_CALL_LOG_CODES_SUCCESS',
    'NAV_LINK_CHANGED',
    'SEARCH_PATIENTS_SUCCESS',
    'SEARCH_PATIENTS_FAILURE',
    'RECEIVED_UNENROLL_REASONS',
    'RESET_SEARCH_RESULE',

    'REQUEST_CHART_CLOSURE',
    'REQUEST_CHART_CLOSURE_COMPLETED',
    'CHART_CLOSURE_REQUEST_FOUND',
    'REMOVE_CHART_CLOSURE_REQUEST',

    // Fax matching constants
    'FAX_MATCHING_PATIENT_LIST_RECEIVED',
    'FAX_QUEUE_RECORD_RECEIVED',
    'RECORD_FIXED_SUCCESSFULLY',
    'RECORD_ARCHIVE_SUCCESSFULLY',
    'RECORD_UMMATCHED_DATA_RECEIVED',
    'RESET_UNMATCHED_DATA',
    'FAX_APPROVAL_QUEUE_RECORD_RECEIVED',

    'INBOUND_RECORDS_RECEIVED_TO_FIX_QUEUE',
    'OPT_OUT_REASONS_RECEIVED',
    'ON_PATIENT_PHARMACY_OPTIONS_RECEIVED',
    'ON_PATIENT_CHARTSPAN_PROVIDERS_RECEIVED',
    'CARE_PLAN_TYPES',
    'PATIENT_CARE_PLAN_RECEIVED',
    'CARE_PLAN_QUESTION_RECEIVED',
    'UPDATE_GAPS_ASSESSMENT_ANSWERS',
    'GAPS_ANSWERS_RECEIVED',
    'GAPS_HISTORY_RECEIVED',
    'CALL_ASSISTANCE_TYPES',

    'PATIENT_EDUCATIONS_RECEIVED',
    'EDUCATION_ADDED_SUCCESSFULLY',
    'ON_PATIENT_MEDICATION_OPTIONS_RECEIVED',
    'ON_GOAL_REVIEW_FREQUENCY_RECEVIED',
    'PATIENT_ENGAGEMENT_HISTIRY_RECEIVED',

    'ON_TEAMS_RECEVIED',
    'ON_CLINICIANS_RECEIVED',
    'ON_TEAM_SELECTED',
    'ON_CLINICIAN_SELECTED',

    'ON_GOAL_TYPES_RECEIVED',
    'ON_GOAL_SOURCES_RECEIVED',
    'ON_GOAL_COMPLETION_STATUS_RECEIVED',
    'ON_GOAL_STEP_TYPES_RECEIVED',

    'ON_OPEN_CHART_SUMMARY',
    'ON_CLOSE_CHART_SUMMARY',
    'ON_OPEN_CHART_TAB',

    'MYQUEUE_COUNTS_RECEIVED',
    'ALLPATIENTS_COUNTS_RECEIVED',

    //Case Messages
    'CCM_CASE_MESSAGES_UNREAD_REQUEST_SUCCESS',
    'CCM_CASE_MESSAGES_UNREAD_REQUEST_FAILED',
    'CCM_CASE_MESSAGES_LIST_REQUEST_SUCCESS',
    'CCM_CASE_MESSAGES_LIST_REQUEST_FAILED',
    'PATIENT_CASE_MESSAGES_REQUEST_SUCCESS',
    'PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS',
    'PATIENT_CASE_DETAIL_MESSAGES_SUCCESS',
    'RESET_PATIENT_CASES',
    'ON_ALL_CLINICIANS_RECEIVED',

    'APCM_TRANSITION_APPROVED',
    'APCM_TRANSITION_DECLINED',

    'APCM_DECLINE_REASONS'
);
