import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as adminActionCreator from '../../actions/admin';
import { Modal, Button } from 'react-bootstrap';

class PatientChartClosureRequestedDataModal extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {};
    }

    showPHS = () => {
        this.props.adminActions.getPHSPdf( this.props.patient, ( hasError ) => {
            if (!hasError) {
                window.open(this.props.phsPdfUrl, '_blank').focus();
            }
        });
    };

    showConfidentialNotes = () => {
        this.props.adminActions.getConfidentialNotesPdf( this.props.patient, ( hasError ) => {
            if (!hasError) {
                window.open(this.props.confidentialNotesPdfUrl, '_blank').focus();
            }
        });
    };

    showCCDA = () => {
        this.props.adminActions.getCCDAPdf( this.props.patient )
            .then(() => {
                window.open(this.props.ccdaPdfUrl, '_blank').focus();
            })
            .catch(() => false);
    };

    showMedSummary = () => {
        this.props.adminActions.getMedSummaryPdf( this.props.patient )
            .then(() => {
                window.open(this.props.medSummaryPdfUrl, '_blank').focus();
            })
            .catch(() => false);
    };

    componentWillReceiveProps( nextProps ) {
        if ( !this.props.patient && nextProps.patient ) {
            this.props.adminActions.getCCDAAvailability( nextProps.patient );
            this.props.adminActions.getMedSummaryAvailability( nextProps.patient );
            this.props.adminActions.getConfidentialNotesAvailability( nextProps.patient );
        } else if ( ( this.props.patient && nextProps.patient ) && ( this.props.patient !== nextProps.patient ) ) {
            this.props.adminActions.getCCDAAvailability( nextProps.patient );
            this.props.adminActions.getMedSummaryAvailability( nextProps.patient );
            this.props.adminActions.getConfidentialNotesAvailability( nextProps.patient );
        }

    }

    render() {
        const { ccdaAvailable, medSummaryAvailable, confidentialNotesAvailable , loadNoteNeeded} = this.props;
        return(
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Chart Closure Requested</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-align-center">
                        <Button bsStyle="success"
                                id="openPhsButton"
                                onClick={ this.showPHS }>
                            Open PHS PDF
                        </Button>
                        { loadNoteNeeded && !confidentialNotesAvailable ?
                            <div>Confidential Notes Summary is unavailable for the patient.</div>
                            :
                            <Button bsStyle="success"
                                    id="showConfidentialNotes"
                                    style={{ marginLeft: "10px" }}
                                    onClick={ this.showConfidentialNotes }>
                                Confidential Notes Summary
                            </Button>
                        }
                        { !ccdaAvailable ?
                            <div>CCDA is unavailable for the patient.</div>
                            :
                            <Button bsStyle="success"
                                    id="openCCDAButton"
                                    style={{ marginLeft: "10px" }}
                                    onClick={ this.showCCDA }>
                                Open CCDA PDF
                            </Button>
                        }
                        { !medSummaryAvailable ?
                            <div>Med Summary is unavailable for the patient.</div>
                            :
                            <Button bsStyle="success"
                                    id="openMedSummaryButton"
                                    style={{ marginLeft: "10px" }}
                                    onClick={ this.showMedSummary }>
                                Open Med Summary
                            </Button>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="default" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    ccdaAvailable: state.levelTwoDashboard.ccdaAvailable,
    confidentialNotesAvailable: state.levelTwoDashboard.confidentialNotesAvailable,
    ccdaPdfUrl: state.levelTwoDashboard.ccdaPdfUrl,
    phsPdfUrl: state.levelTwoDashboard.phsPdfUrl,
    confidentialNotesPdfUrl: state.levelTwoDashboard.confidentialNotesPdfUrl,
});

const mapDispatchToProps = ( dispatch ) => ({
    adminActions: bindActionCreators( adminActionCreator, dispatch ),
});

export default connect( mapStateToProps, mapDispatchToProps )( PatientChartClosureRequestedDataModal );
