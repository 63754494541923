import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Panel,Button} from 'react-bootstrap';
import _ from 'lodash';

class PHS extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasCheckedPHS: false,
            hasCheckedConfidentialNotes: false,
            hasError: false
        };
    }

    componentDidMount() {
        let selectedPatient = this.props.selectedPatient;

        if (!selectedPatient) {
            this.showErrorMessage('Unknown Patient', 'Patient Details aren\'t Available. Please Refresh the Page or Try Again Later.');
            this.setState({hasCheckedPHS: true, hasCheckedConfidentialNotes: true, hasError: true});
        } else {
            this.props.adminActions.getPHSAvailability(selectedPatient.id, hasError => this.setState({hasCheckedPHS: true, hasError: hasError}));
            if(this.props.loadNoteNeeded){
                this.props.adminActions.getConfidentialNotesAvailability(selectedPatient.id, hasError => this.setState({hasCheckedConfidentialNotes: true, hasError: hasError}));
            }
        }
    }

    showErrorMessage(title, message){
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    showPHS() {
        let selectedPatient = this.props.selectedPatient;

        if (!selectedPatient) {
            this.showErrorMessage('Unknown Patient', 'Patient Details aren\'t Available. Please Refresh the Page or Try Again Later.');
        } else {
            this.props.adminActions.getPHSPdf(selectedPatient.id, hasError => {
                if (!hasError) {
                  const newWindow = window.open(this.props.phsPdfUrl, '_blank');
                  if(newWindow) {
                    // Show popup block error, if window.open is null/undefined
                    newWindow.focus();
                  }else{
                    this.showErrorMessage('Error','Please disable popup blocker to download PHS ');
                  }
                }
            });
        }
    }

    showConfidentialNotes() {
        let selectedPatient = this.props.selectedPatient;

        if (!selectedPatient) {
            this.showErrorMessage('Unknown Patient', 'Patient Details aren\'t Available. Please Refresh the Page or Try Again Later.');
        } else {
            this.props.adminActions.getConfidentialNotesPdf(selectedPatient.id, hasError => {
                if (!hasError) {
                  const newWindow = window.open(this.props.confidentialNotesPdfUrl, '_blank');
                  if(newWindow) {
                    // Show popup block error, if window.open is null/undefined
                    newWindow.focus();
                  }else{
                    this.showErrorMessage('Error','Please disable popup blocker to download PHS ');
                  }
                }
            });
        }
    }


    render() {
        const { hasCheckedPHS, hasCheckedConfidentialNotes } = this.state;
        const { phsAvailable, confidentialNotesAvailable,loadNoteNeeded } = this.props;
        return (
            <Panel>
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    {!hasCheckedPHS &&
                        <div><i>Checking for PHS availability...</i></div>
                    }
                    {hasCheckedPHS && !phsAvailable &&
                        <div>PHS is unavailable for the patient.</div>
                    }
                    {hasCheckedPHS && phsAvailable &&
                        <div>
                            <Button bsStyle="success" id="openPhsButton" onClick={this.showPHS.bind(this)}>
                                Open PHS PDF
                            </Button>
                        </div>
                    }
                </div>
                <div style={{ textAlign: 'center' }}>
                    {loadNoteNeeded && !hasCheckedConfidentialNotes &&
                        <div><i>Checking for Confidential Notes Summary availability...</i></div>
                    }
                    {hasCheckedConfidentialNotes && !confidentialNotesAvailable &&
                        <div>Confidential Notes Summary is unavailable for the patient.</div>
                    }
                    {hasCheckedConfidentialNotes && confidentialNotesAvailable &&
                        <div>
                            <Button bsStyle="success" id="showConfidentialNotes" onClick={this.showConfidentialNotes.bind(this)}>
                                Confidential Notes Summary
                            </Button>
                        </div>
                    }
                </div>
            </Panel>
        );
    }
}

const mapStateToProps = (state) => ({
    'phsAvailable': state.levelTwoDashboard.phsAvailable,
    'phsPdfUrl': state.levelTwoDashboard.phsPdfUrl,
    'confidentialNotesAvailable': state.levelTwoDashboard.confidentialNotesAvailable,
    'confidentialNotesPdfUrl': state.levelTwoDashboard.confidentialNotesPdfUrl,
    selectedPatient: state.app.selectedPatient,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PHS);