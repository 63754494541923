import React from 'react';
import { bindActionCreators } from 'redux';
import * as authActionCreator from '../actions/authactions';
import * as appActionCreator from '../actions/app';
import * as adminActionCreator from '../actions/admin';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { isAdmin, isMA,isTriage } from '../utils';
import { ADMIN_ROLE, MA_ROLE , ROLE_MULTITEAM} from '../constants/other';
import _ from 'lodash';
import { Navbar, Nav , NavItem, NavDropdown, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import config from "../config";
import ChangeProfilePopup from "./ChangeProfilePopup";

class Header extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openChangeProfile:false
        }
    }

    handleLogout = () => {
        if (!this.props.chartOpened) {
            this.props.actions.logoutAndRedirect();
        }
    }

    
    componentWillReceiveProps(nextProps) {
        if ( this.props.activeUser != nextProps.activeUser ) {
            const isRPMUser = nextProps.activeUser && nextProps.activeUser.center && nextProps.activeUser.center.rpm_flag;
            const ce_flag = nextProps.activeUser.center.ce_flag;
            if(isRPMUser) {
                this.props.adminActions.getRPMPerformance(nextProps.activeUser.id)
            }else if(ce_flag){
                this.props.adminActions.getCnePerformance( nextProps.activeUser.id );
            }else{
                this.props.adminActions.getUtilization( nextProps.activeUser.id );
            }


            const userEmail = nextProps.activeUser.email;
            const center_id = nextProps.activeUser.center && nextProps.activeUser.center.id ?
                nextProps.activeUser.center.id  : "";
            // const orgId = nextProps.patientInfo.provider ? nextProps.patientInfo.provider.organization_id : -1;
            const userObject = {
                email : userEmail,
                custom : {
                    "user_id": nextProps.activeUser.id+"",
                    "center_id": center_id+""
                }
            };

        }
    }

    handleNavigation = (path) => {
        if (!this.props.chartOpened) {
            this.props.appActions.redirectTo(`/home/${path}`);
        }
    }

    openChangeProfile = () => {
        this.setState({openChangeProfile:true});
    }

    hideChangeProfile = () => {
        this.setState({openChangeProfile:false});
    }

    handleOpenProfile = (profile) => {
        const {user} =  this.props;
        if(user.id !== profile.id){
            this.props.actions.changeProfile(this.props.user.token,profile);
        }
    }

    getRoleName = (userRole) => {
        switch(userRole) {
            case ADMIN_ROLE:
                return 'Admin';
            case MA_ROLE:
                return 'Medical Specialist';
            case ROLE_MULTITEAM:
                return 'Multiteam Medical Specialist'    
        }
    }

    billableTooltip() {
        const { utilizationBillable, utilizationOverage, utilizationPercentBillable } = this.props;
        return (
            <Tooltip id="tooltip">
                <p style={{margin: '5px'}}><strong>Billable Minutes: </strong>{ utilizationBillable && utilizationBillable.toFixed(0)}</p>
                <p style={{margin: '5px'}}><strong>Overage Minutes: </strong>{ utilizationOverage && utilizationOverage.toFixed(0)}</p>
                <p style={{margin: '5px'}}><strong>Percent Billable: </strong>{ utilizationPercentBillable && `${utilizationPercentBillable.toFixed(0)}%`}</p>
            </Tooltip>
        )
    }

    render() {
        const { chartOpened, user, activeUser, companies, notificationUnreadCount, utilizationPercentBillable,
                consents, declines, caseMessagesAvailable} = this.props;
        const isUserAdmin = isAdmin(user.roles);
        let isMARole = isMA(user.roles);
        let isTriageRole = isTriage(user.roles)
        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
        let company = null;
        let ce_flag, isRetSpec;
        const isRPM = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const isGAPS = activeUser && activeUser.center && activeUser.center.gaps_flag;
        if (activeUser && activeUser.center && activeUser.center.company_id) {
            company = _.result(_.find(companies, {'id':activeUser.center.company_id}), 'name');
            ce_flag = activeUser.center.ce_flag;
            isRetSpec = activeUser.center.support_flag;
        }
        const isChartspan = activeUser && activeUser.center.id === 1;
        return (
            <Navbar fluid inverse fixedTop className="header">
                <Navbar.Header>
                    <Navbar.Brand>
                        <a className="navbar-brand" href="#/home">
                            <span className="ccm-logo-container">
                                <img
                                    alt="ChartSpan"
                                    title="ChartSpan"
                                    style={{ width: 135 }}
                                    src="images/chartspan_logo.png"
                                />
                            </span>
                        </a>
                        <span className="user-detail-container" style={{ marginLeft: '30px', marginTop: '5px' }}>
                            <span className="user-name">
                                {user.name}
                            </span>
                            <span className="user-role">
                                {this.getRoleName(user.roles[0])}
                            </span>
                        </span>
                        <span className="user-detail-container" style={{ marginLeft: '30px', marginTop: '5px' }}>
                            <span className="user-name">
                                {activeUser && activeUser.center && activeUser.center.name}
                            </span>
                            <span className="user-role">
                                {company}
                            </span>
                        </span>
                        { ce_flag === false && !isRPM &&
                            <OverlayTrigger placement="bottom" overlay={this.billableTooltip()}>
                                <span className="user-detail-container" style={{marginLeft: '30px', marginTop: '5px'}}>
                                    <span className="user-name">
                                        Performance
                                    </span>
                                    <span className="user-role">
                                        { isNaN(utilizationPercentBillable) ? '-' : `${utilizationPercentBillable.toFixed(0)}%` }
                                    </span>
                                </span>
                            </OverlayTrigger>
                        }
                        { (ce_flag === true || isRPM) &&
                            <span className="user-detail-container" style={{ marginLeft: '30px', marginTop: '5px' }}>
                                <span className="user-name">
                                    Performance
                                </span>
                                <span className="user-role">
                                    { isNaN(consents) ? '-' : `${consents} Consents | ${declines} Declines` }
                                </span>
                            </span>
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav bsStyle="pills" activeKey={this.props.activeNavLink} pullRight>
                    {isUserAdmin && !ce_flag && !isGAPS && !isRetSpec && companies && !isRPMUser && <NavItem
                                eventKey="caseMessages"
                                id="headerCaseMessages"
                                disabled={chartOpened}
                                onClick={() => this.handleNavigation('caseMessages')}
                            >
                                Case Messages
                                {caseMessagesAvailable ?
                                    <span
                                        className="badge"
                                        style={{ fontSize:2,display: 'block', backgroundColor: '#FF0000', color: '#FFFFFF',
                                        padding: 5,right: 4,top: 15, position:'absolute' }}
                                    >
                                       
                                    </span>
                                    : ''
                                }
                            </NavItem>
                        }
                        {isUserAdmin && !ce_flag && !isGAPS && !isRetSpec && companies && !isRPMUser &&
                            <NavItem
                                eventKey="notification"
                                id="headerNotification"
                                disabled={chartOpened}
                                onClick={() => this.handleNavigation('notification')}
                            >
                                Notifications
                                {notificationUnreadCount ?
                                    <span
                                        className="badge"
                                        style={{ display: 'inline',position: 'absolute', top: 8,right: -3, backgroundColor: '#FF0000', color: '#FFFFFF' }}
                                    >
                                        {notificationUnreadCount}
                                    </span>
                                    : ''
                                }
                            </NavItem>
                        }
                        {isChartspan && !ce_flag && companies &&
                            <NavDropdown
                                title="Faxes"
                                id="faxDropdown"
                                eventKey="faxes"
                                disabled={chartOpened}
                            >
                                <MenuItem
                                    eventKey="medicalRecords"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('medicalRecords')}
                                >
                                    Fax Matching
                                </MenuItem>
                                <MenuItem
                                    eventKey="fixFaxRecords"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('fixFaxRecords')}
                                >
                                    Fix Fax Records
                                </MenuItem>
                            </NavDropdown>
                        }
                        <NavDropdown
                            title="Queues"
                            id="queueDropdown"
                            eventKey="queues"
                            disabled={chartOpened}
                        >  
                            <MenuItem
                                    eventKey="mypatients"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('mypatients')}
                                >
                                    My Patients
                            </MenuItem>
                            {
                                 <MenuItem
                                 eventKey="myTeamPatients"
                                 disabled={chartOpened}
                                 onClick={() => this.handleNavigation('myTeamPatients')}
                                    >
                                        My Team
                                </MenuItem>
                                
                            }
                             { !isGAPS && !isRPM && !ce_flag && companies && !isRetSpec &&
                                <MenuItem
                                        eventKey="allPatients"
                                        disabled={chartOpened}
                                        onClick={() => this.handleNavigation('allPatients')}
                                    >
                                        All Patients
                                </MenuItem>
                            }
                            {
                                isRPM && <MenuItem
                                    eventKey="rmpenrollment"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('rpmenrollment')}
                                >
                                    Enrollment
                                </MenuItem>
                            }
                            {
                                isGAPS && <MenuItem
                                    eventKey="GAPSColorectal"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('gAPSColorectal')}
                                >
                                    Colorectal
                                </MenuItem>
                            }
                            {
                                isGAPS && <MenuItem
                                    eventKey="GAPSBreastCancer"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('gAPSBreastCancer')}
                                >
                                    Breast Cancer
                                </MenuItem>
                            }
                            {
                                isGAPS && <MenuItem
                                    eventKey="GAPSDiabeticEye"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('gAPSDiabeticEye')}
                                >
                                    Diabetic Eye
                                </MenuItem>
                            }
                            { !isGAPS && !isRPM && !ce_flag && companies && !isRetSpec &&
                                <MenuItem
                                    eventKey="records"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('records')}
                                >
                                    New Records
                                </MenuItem>
                            }

                            { false && !isGAPS && !isRPM &&  !ce_flag &&
                            <MenuItem
                                eventKey="strokecare"
                                disabled={chartOpened}
                                onClick={() => this.handleNavigation('strokecare')}
                            >
                                Pathways - Stroke Care
                            </MenuItem>
                            }

                            { !isGAPS && !isRPM &&  ce_flag && companies && !isRetSpec &&
                                <MenuItem
                                    eventKey="unverified"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('unverified')}
                                >
                                    Unverified
                                </MenuItem>
                            }
                            { !isGAPS && !isRPM && ce_flag &&
                            <MenuItem
                                eventKey="optOut"
                                disabled={chartOpened}
                                onClick={() => this.handleNavigation('optOut')}
                            >
                                Opt Out
                            </MenuItem>
                            }
	                        { !isGAPS && !isRPM && isRetSpec && companies &&
		                        <MenuItem
			                        eventKey="patientServices"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('patientServices')}
		                        >
			                        Patient Services
		                        </MenuItem>
	                        }
	                        { !isGAPS && !isRPM && isRetSpec && companies &&
		                        <MenuItem
			                        eventKey="patientServicesOpenCharts"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('patientServicesOpenCharts')}
		                        >
			                        Patient Services - Open Charts
		                        </MenuItem>
	                        }
                             {!isGAPS && !isRPM &&  !ce_flag && (isTriageRole || isUserAdmin) &&
                                <MenuItem
                                    eventKey="urgentTriage"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('urgentTriage')}
                                >
                                    Urgent - Triage
                                </MenuItem>
                            }
                            {!isGAPS && !isRPM && isUserAdmin && companies && !ce_flag && !isRetSpec &&
                            <MenuItem
                                eventKey="urgentITMedicalRecord"
                                disabled={chartOpened}
                                onClick={() => this.handleNavigation('urgentITMedicalRecord')}
                            >
                                Urgent - IT Medical Summary
                            </MenuItem>
                            }
                           
                            {!isGAPS && !isRPM && isUserAdmin && companies && !ce_flag && !isRetSpec &&
                            <MenuItem
                                eventKey="urgentDischargeManagement"
                                disabled={chartOpened}
                                onClick={() => this.handleNavigation('urgentDischargeManagement')}
                                >
                                Urgent - Discharge Management
                                </MenuItem>

                            }
                            {!isGAPS && !isRPM && isUserAdmin && companies && !ce_flag && !isRetSpec && false &&
                                <MenuItem
                                    eventKey="urgentCcm"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('urgentCcm')}
                                >
                                    Urgent
                                </MenuItem>
                            }
                            {!isGAPS && !isRPM && isUserAdmin && companies && !ce_flag && !isRetSpec &&
                                <MenuItem
                                    eventKey="urgentNurse"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('urgentNurse')}
                                >
                                    Urgent - Nurse
                                </MenuItem>
                            }
                            {!isGAPS && !isRPM && isUserAdmin && companies && !ce_flag && !isRetSpec &&
                                <MenuItem
                                    eventKey="urgentRefill"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('urgentRefill')}
                                >
                                    Urgent - Refill
                                </MenuItem>
                            }
	                        {!isGAPS && !isRPM && isUserAdmin && companies && !ce_flag && !isRetSpec &&
		                        <MenuItem
			                        eventKey="urgentLead"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentLead')}
		                        >
			                        Urgent - Lead
		                        </MenuItem>
	                        }
	                        {!isGAPS && !isRPM && isUserAdmin && companies && !ce_flag && !isRetSpec &&
		                        <MenuItem
			                        eventKey="urgentAppointment"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentAppointment')}
		                        >
			                        Urgent - Appointment
		                        </MenuItem>
	                        }
                            {!isGAPS && !isRPM && isUserAdmin && companies && ce_flag && !isRetSpec &&
                                <MenuItem
                                    eventKey="urgentCne"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('urgentCne')}
                                >
                                    Urgent
                                </MenuItem>
                            }
	                        { false && isRetSpec && companies &&
		                        <MenuItem
			                        eventKey="urgentSupport"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentSupport')}
		                        >
			                        Urgent
		                        </MenuItem>
	                        }
	                        { !isGAPS && !isRPM && isRetSpec && companies &&
		                        <MenuItem
			                        eventKey="urgentNoAnswer"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentNoAnswer')}
		                        >
			                        Urgent - No Answer
		                        </MenuItem>
	                        }
                            {!isGAPS && (isRPM || (isUserAdmin && companies && ( ce_flag || isRetSpec )) ) &&
                                <MenuItem
                                    eventKey="urgentBadPhone"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('urgentBadPhone')}
                                >
                                    Urgent - Bad Phone
                                </MenuItem>
                            }
	                        {!isGAPS && (isRPM || (isUserAdmin && companies && ce_flag && !isRetSpec)) &&
		                        <MenuItem
			                        eventKey="urgentPoa"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentPoa')}
		                        >
			                        Urgent - POA
		                        </MenuItem>
	                        }
	                        {!isGAPS && (isRPM ||  (isUserAdmin && companies && ce_flag && !isRetSpec)) &&
		                        <MenuItem
			                        eventKey="urgentRequestedInfo"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentRequestedInfo')}
		                        >
			                        Urgent - Requested Info
		                        </MenuItem>
	                        }
	                        {!isGAPS && (isRPM || (isUserAdmin && companies && ( ce_flag || isRetSpec ) ))&&
		                        <MenuItem
			                        eventKey="urgentCallBack"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentCallBack')}
		                        >
			                        Urgent - Call Back
		                        </MenuItem>
	                        }
	                        {!isGAPS && !isRPM && companies && !ce_flag && !isRetSpec &&
		                        <MenuItem
			                        eventKey="urgentPreEnrollment"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentPreEnrollment')}
		                        >
			                        Urgent - Pre-Enrollment
		                        </MenuItem>
	                        }
	                        {!isGAPS && !isRPM && companies && !ce_flag && !isRetSpec &&
		                        <MenuItem
			                        eventKey="urgentMips"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentMips')}
		                        >
			                        Urgent - MIPS
		                        </MenuItem>
	                        }
	                        {!isGAPS && !isRPM && companies && !ce_flag && !isRetSpec &&
		                        <MenuItem
			                        eventKey="urgentCarePlan"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentCarePlan')}
		                        >
			                        Urgent - Provider Communications
		                        </MenuItem>
	                        }
	                        {!isGAPS && (isRPM || ( isUserAdmin && companies && ( ce_flag || isRetSpec ) ) )&&
		                        <MenuItem
			                        eventKey="urgentCopay"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentCopay')}
		                        >
			                        Urgent - Copay/Insurance
		                        </MenuItem>
	                        }
	                        {!isGAPS && !isRPM && isUserAdmin && companies && ( ce_flag || isRetSpec ) &&
		                        <MenuItem
			                        eventKey="urgentProviderChange"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentProviderChange')}
		                        >
			                        Urgent - Provider Change
		                        </MenuItem>
	                        }
                            {!isGAPS && !isRPM && isUserAdmin && companies && ( !ce_flag ) &&
                            <MenuItem
                                eventKey="urgentAccount"
                                disabled={chartOpened}
                                onClick={() => this.handleNavigation('urgentAccount')}
                            >
                                Urgent - Account
                            </MenuItem>
                            }
	                        {!isGAPS && !isRPM && isUserAdmin && companies && ce_flag && !isRetSpec &&
		                        <MenuItem
			                        eventKey="urgentRoundTwo"
			                        disabled={chartOpened}
			                        onClick={() => this.handleNavigation('urgentRoundTwo')}
		                        >
			                        Urgent - Round Two
		                        </MenuItem>
	                        }
                            { false && !isGAPS && !isRPM && !ce_flag && companies && !isRetSpec &&
                                <MenuItem
                                    eventKey="new"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('new')}
                                >
                                    New
                                </MenuItem>
                            }
                            {false && !isRPM && !ce_flag && companies && !isRetSpec &&
                                <MenuItem
                                    eventKey="active"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('active')}
                                >
                                    Active
                                </MenuItem>
                            }
                        </NavDropdown>
                        { companies &&
                            <NavItem
				                id="headerLookupPatient"
                                eventKey="search"
                                disabled={chartOpened}
                                onClick={() => this.handleNavigation('search')}
                            >
                                Lookup Patient
                            </NavItem>
                        }

                        {
                            user && user.user_profiles && user.user_profiles.length > 1 &&
                                <NavItem
                                    title="Profiles"
                                    id="profileDropdown"
                                    eventKey="profiles"
                                    disabled={chartOpened}
                                    onClick={()=>this.openChangeProfile()}>

                                    Profiles

                                </NavItem>
                        }
                        {isUserAdmin && !ce_flag && companies &&
                            <NavDropdown
                                title="Admin"
                                id="adminDropdown"
                                eventKey="admin"
                                disabled={chartOpened}
                            >
                                <MenuItem
                                    eventKey="openCharts"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('openCharts')}
                                >
                                    Open Charts
                                </MenuItem>
                                <MenuItem
                                    eventKey="pccPatientAssignment"
                                    disabled={chartOpened}
                                    onClick={() => this.handleNavigation('pccPatientAssignment')}
                                >
                                    PCC - Patient Assignment
                                </MenuItem>
                            </NavDropdown>
                        }

                        <NavItem
                            eventKey="logout"
                            disabled={chartOpened}
                            onClick={this.handleLogout}
                        >
                            <span className="logout-label">Logout</span>
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
                <ChangeProfilePopup show={this.state.openChangeProfile} onHide={this.hideChangeProfile}/>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => ({
    activeNavLink: state.app.activeNavLink,
    chartOpened: state.app.chartOpened,
    user: state.auth,
    notificationUnreadCount: state.notifications.notificationUnreadCount,
    caseMessagesAvailable: state.notifications.caseMessagesAvailable,
    activeUser: state.admin.activeUser,
    companies: state.admin.companies,
    utilizationBillable: state.admin.utilizationBillable,
    utilizationOverage: state.admin.utilizationOverage,
    utilizationPercentBillable: state.admin.utilizationPercentBillable,
    consents: state.admin.consents,
    declines: state.admin.declines,
    configParams: state.admin.configParams

});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(authActionCreator, dispatch),
    appActions: bindActionCreators(appActionCreator, dispatch),
    adminActions: bindActionCreators(adminActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
