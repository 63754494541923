/**
 * Created by synerzip on 21/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import DateTimePicker from 'react-bootstrap-datetimepicker-custom-icons';
import {Input,Modal,Button,FormControl,FormGroup,ControlLabel} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import ConfirmDeleteGoal from './ConfirmDeleteGoal'
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class EditCareGoal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            goalId:null,
            goalTitle:null,
            diagonsisCode:null,
            goalTypeId:null,
            sourceId:false,
            otherNote:'',
            frequency:'',
            showDeleteGoalConfirm:false,
            dueDate:null
        }
        this.onDueDateChanged = this.onDueDateChanged.bind(this);
    }

    componentDidMount(){
        const {goalTypes,goalSources,stepTypes} = this.props;
        if(!goalTypes){
            this.props.adminActions.getGoalTypes();
        }
        if(!goalSources){
            this.props.adminActions.getGoalSources();
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.goalId != nextProps.goalId || 
            nextProps.goalId != this.state.goalId){
            const dueDateString = nextProps.dueDate ? 
            moment(nextProps.dueDate,'YYYY-MM-DD').format('MM/DD/YYYY'): '';
                
            this.setState({
                goalTitle:nextProps.goalTitle,
                diagonsisCode:nextProps.diagonsisCode,
                goalTypeId:nextProps.goalTypeId,
                sourceId:nextProps.sourceId,
                otherNote:nextProps.otherNote,
                frequency:nextProps.frequency,
                goalId:nextProps.goalId,
                dueDate:dueDateString
            })
        }
    }

    handleNotesChange(e){
        this.setState({otherNote:e.target.value});
    }

    handleFrequenceChange(e){
        this.setState({frequency:e.target.value});
    }

    handleDiagonisisCode(e){
        this.setState({diagonsisCode:e.target.value});
    }

    onGoalTypeChanged(goalType){
        this.setState({goalTypeId:goalType.id});
    }
    onGoalTypeSourceChanged(source){
        this.setState({sourceId:source.id});
    }
    onSubmit(e) {
        e.preventDefault();
        let isError = false;
        const dueDateString = moment(this.state.dueDate,'MM-DD-YYYY').format('YYYY-MM-DD')
        const {selectedPatient,adminActions} = this.props;
        if( !this.state.diagonsisCode){
            this.setState({
                diagonsisCodeError: true
            })
            isError = true;
        }else{
            this.setState({
                diagonsisCodeError: false
            })
        }
        if( !this.state.goalTypeId){
            this.setState({
                goalTypeIdError: true
            })
            isError = true;
        }else{
            this.setState({
                goalTypeIdError: false
            })
        }
        if(!this.state.sourceId){
            this.setState({
                sourceIdError: true
            })
            isError = true;
        }else{
            this.setState({
                sourceIdError: false
            })
        }

        if(!this.state.dueDate){
            this.setState({
                dueDateError: 'Invalid Date'
            })
            isError = true;
        }else{
            this.setState({
                dueDateError: false
            })
        }

        if(!isError){
            this.props.showSecondaryTimer();
            const dueDateString = moment(this.state.dueDate,'MM-DD-YYYY').format('YYYY-MM-DD')
            adminActions.updateGoal(selectedPatient.id,
                this.state.goalId,
                this.state.goalTitle,
                this.state.diagonsisCode,
                this.state.goalTypeId, 
                this.state.sourceId,
                this.state.frequency,
                this.state.otherNote,
                dueDateString);
            this.setState(
                {
                    goalId:null,
                    goalTitle:null,
                    dueDate:null,
                    diagonsisCode:null,
                    goalTypeId:null,
                    sourceId:false,
                    otherNote:'',
                    frequency:'',
                    stepError:false,
                    diagonsisCodeError:false,
                    goalTypeIdError:false,
                    sourceIdError:false,
                    dueDateError:false
                }
            );
            this.props.onPopupHide();            
        }
    }

    deleteGoal(){
        const {selectedPatient,goalId} = this.props;
        this.setState({showDeleteGoalConfirm:false});
        this.props.adminActions.deleteCareGoal(selectedPatient.id,
            goalId);
        this.onCancel();    

    }

    onCancel() {
        this.setState(
            {
                goalId:null,
                goalTitle:null,
                diagonsisCode:null,
                goalTypeId:null,
                sourceId:false,
                otherNote:'',
                frequency:'',
                dueDateError:false,
                dueDate:null
            }
        );
        this.props.onCloseEdit();
        this.props.onPopupHide();
    }

    showDeleteGoalConfirm(){
        this.setState({showDeleteGoalConfirm:true});
    }

    onDueDateChanged(dateValue){
        if(dateValue === 'Invalid date'){
            this.setState({dueDateError:"Invalid Date"});
            this.setState({dueDate:''});
        }else{
            this.setState({dueDate:dateValue});
            this.setState({dueDateError:false});
        }
    }

    render(){
        const {goalReviewFrequency,goalTypes,
            goalSources,stepTypes,selectedPatient} = this.props;
        const currentGoalTitle = this.props.goalTitle;
        const currentDiagonsisCode = this.props.diagonsisCode;    
        return (
            <Modal show={this.props.show} className="add-goal-modal">
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>Edit Care Goal</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto'}}>
                        <form onSubmit={this.onSubmit.bind(this)} novalidate>
                         <div style={{display:'flex',padding:15,marginBottom:15,
                            backgroundColor:'rgb(210,230,243)',borderRadius:5}}>
                            <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                <div style={{display:'flex',fontWeight:600}}>
                                    <span>Care Goal:</span> 
                                    <span style={{paddingLeft:10}}>{currentGoalTitle}</span>
                                </div>
                                <div style={{display:'flex',fontWeight:600}}>
                                    <span>Diagnosis:</span> 
                                    <span style={{paddingLeft:10}}>{currentDiagonsisCode}</span>
                                </div>
                            </div>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <Button bsStyle="danger" onClick={this.showDeleteGoalConfirm.bind(this)} 
                                    style={{float:'left'}}>
                                    Delete Care Goal
                                </Button>
                            </div>
                         </div>   
                        
                            <Input type="text" label="Care Goal Title" placeholder="Enter Care Goal Title"
                                   valueLink={this.linkState('goalTitle')} maxLength="255" required/>   
                            <FormGroup className={this.state.diagonsisCodeError}>                        
                                <ControlLabel>Diagnosis Code</ControlLabel>
                                <select style={{padding: '6px 12px'}} 
                                    className="form-control"
                                    onChange={this.handleDiagonisisCode.bind(this)} 
                                    value={this.state.diagonsisCode}>
                                    <option value='' selected disabled>Choose Code</option>
                                    {
                                        selectedPatient.icd10code_1 && 
                                        <option value={`${selectedPatient.icd10code_1}-${selectedPatient.icd10code_1_desc}`}>
                                            {`${selectedPatient.icd10code_1}-${selectedPatient.icd10code_1_desc}`}
                                        </option>
                                    }
                                    {
                                        selectedPatient.icd10code_2 &&  
                                        <option value={`${selectedPatient.icd10code_2}-${selectedPatient.icd10code_2_desc}`}>
                                            {`${selectedPatient.icd10code_2}-${selectedPatient.icd10code_2_desc}`}
                                        </option>
                                    }
                                    <option value='Other - Patient Reported'>Other - Patient Reported</option>
                                </select>
                                {this.state.diagonsisCodeError && <span className="text-danger"><small>Please select diagonsis code</small></span>}
                            </FormGroup>
                            <FormGroup className={this.state.dueDateError}>                        
                                <ControlLabel>Due Date</ControlLabel>
                                <div style={{width:300, position: 'relative'}}>
                                    <DateTimePicker format="MM-DD-YYYY"
                                                    inputFormat="MM/DD/YYYY"
                                                    dateTime={ moment().format("MM-DD-YYYY") }
                                                    minDate={ moment() }
                                                    defaultText={this.state.dueDate}
                                                    ref="DueDateRef"
                                                    inputProps={{
                                                        maxLength: 10,
                                                        style: { height: '36px !important' },
                                                        ref: (ref) => { this.dateInput = ref },
                                                        placeholder: 'Due Date(MM/DD/YYYY)',
                                                    }}
                                                    iconStyle={{
                                                        class: 'fa',
                                                        time: 'fa-clock-o',
                                                        calendar: 'fa-calendar',
                                                        up: 'fa-chevron-up',
                                                        down: 'fa-chevron-down',
                                                        right: 'fa-chevron-right',
                                                        left: 'fa-chevron-left',
                                                    }}
                                                    onChange={this.onDueDateChanged}/>
                                </div>   
                                {this.state.dueDateError && <span className="text-danger"><small>
                                    {this.state.dueDateError}
                                </small></span>}
                            </FormGroup>    
                            <div style={{display:'flex',
                                        justifyContent:'space-between',}}>
                                <div>
                                    <ControlLabel>Goal Type</ControlLabel>
                                    <div>
                                        {
                                                _.map(goalTypes,(goalType) => {
                                                    return <div key={goalType.id} style={{display:'flex',alignItems:'center'}}>
                                                        <input type="radio" name="goalTypeRadio"
                                                               style={{height:'15px !important',marginBottom:3}}
                                                               checked={goalType.id == this.state.goalTypeId}
                                                               value={goalType.id}
                                                               onChange={this.onGoalTypeChanged.bind(this,goalType)}/>
                                                        <div style={{marginLeft:5}}>
                                                            {goalType.name}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                    </div>
                                    {this.state.goalTypeIdError && <span className="text-danger"><small>Please select goal type</small></span>}
                                    
                                </div>
                                <div style={{marginRight:15}}>
                                    <ControlLabel>Source</ControlLabel>
                                    <div>
                                        {
                                                _.map(goalSources,(source) => {
                                                    return <div key={source.id} style={{display:'flex',alignItems:'center'}}>
                                                        <input type="radio" name="sourceRadio"
                                                               style={{height:'15px !important',marginBottom:3}}
                                                               checked={source.id == this.state.sourceId}
                                                               value={source.id}
                                                               onChange={this.onGoalTypeSourceChanged.bind(this,source)}/>
                                                        <div style={{marginLeft:5}}>
                                                            {source.name}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                    </div>
                                    {this.state.sourceIdError && <span className="text-danger"><small>Please select source</small></span>}
                                </div>
                            </div>    
                            
                            <FormGroup style={{marginTop:15}} 
                                className={this.state.stepError && 'has-error'}>                        
                                <ControlLabel>Care Goal Review Frequency</ControlLabel>
                                <select style={{padding: '6px 12px'}} 
                                    className="form-control"
                                    onChange={this.handleFrequenceChange.bind(this)} value={this.state.frequency}>
                                    <option value='' selected disabled>Select Care Goal Review Frequency</option>
                                    {
                                        _.map(goalReviewFrequency,(frequency) => {
                                            return <option value={frequency.id}>{frequency.frequency}</option>
                                        })
                                    }
                                </select>
                                {this.state.stepError && <span className="text-danger"><small>Please enter a care goal</small></span>}
                            </FormGroup>
                           
                           {
                            this.state.frequency == '5' && <div><textarea className="form-control" rows="5" id="notes"
                                maxLength="256"
                                value={this.state.otherNote}
                                placeholder="Enter Notes"
                                onChange={this.handleNotesChange.bind(this)}></textarea>
                                 <div style={{display:'flex',justifyContent:'flex-end'}}>
                                    {`${this.state.otherNote ? this.state.otherNote.length : 0}/256`}
                                </div>  
                                </div>
                           }
                            
                            <div style={{textAlign:'center',paddingTop:10}}>
                                <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>
                    <ConfirmDeleteGoal
                        show={this.state.showDeleteGoalConfirm} 
                        onPopupHide={()=>this.setState({showDeleteGoalConfirm:false})}
                        onDeleteConfirm={this.deleteGoal.bind(this)}/>       

                </Modal.Body>

            </Modal>
        );
    }
}

reactMixin(EditCareGoal.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient,
    'goalReviewFrequency':state.admin.goalReviewFrequency,
    'stepTypes':state.levelTwoDashboard.stepTypes,
    'goalTypes':state.levelTwoDashboard.goalTypes,
    'goalSources':state.levelTwoDashboard.goalSources,
    'goalCompletionStatuses':state.levelTwoDashboard.goalCompletionStatuses,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCareGoal);